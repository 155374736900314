import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkAuth } from "./store/actions/auth";
import { PrivateRoute } from "./components/PrivateRoute";
// import { Header } from "./components/Header";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Maquinas } from "./containers/Maquinas/Maquinas";
import { Diesel } from "./containers/Diesel/Diesel";
import { Login } from "./containers/Auth/Login";
import { PageNotFound } from "./components/PageNotFound";
import { Clientes } from "./containers/Clientes/Clientes";
import { Proveedores } from "./containers/Proveedores/Proveedores";
import { Operadores } from "./containers/Operadores/Operadores";
import { Obras } from "./containers/Obras/Obras";
import { Accesorios } from "./containers/Accesorios/Accesorios";
import { Traslados } from "./containers/Traslados/Traslados";

import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./lib/themes/default";
import Layout from "./components/Layout";

// "start": "export HTTPS=true&&SSL_CRT_FILE=cert.pem&&SSL_KEY_FILE=key.pem react-scripts start",

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Header></Header>
          </Grid> */}
          <Grid item xs={12}>
            <Container maxWidth="lg">
              <Switch>
                <Route path="/login" exact component={Login} />
                <PrivateRoute path="/" exact redirect="/maquinas" />
                <PrivateRoute path="/diesel" exact component={Diesel} />
                <PrivateRoute path="/maquinas" exact component={Maquinas} />
                <PrivateRoute path="/clientes" exact component={Clientes} />
                <PrivateRoute
                  path="/proveedores"
                  exact
                  component={Proveedores}
                />
                <PrivateRoute path="/operadores" exact component={Operadores} />
                <PrivateRoute path="/obras" exact component={Obras} />
                <PrivateRoute path="/accesorios" exact component={Accesorios} />
                <PrivateRoute path="/traslados" exact component={Traslados} />
                <Route path="/" component={PageNotFound} />
              </Switch>
            </Container>
          </Grid>
        </Grid>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
