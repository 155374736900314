import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOperadores } from "../../store/actions/operadores";
import { BuscarOperador } from "./BuscarOperador";
import { ListaOperadores } from "./ListaOperadores";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from "@material-ui/core/Grid";
import { NuevoOperador } from "./NuevoOperador";

export const Operadores = () => {
  // const [, dispatch] = useContext(OperadoresContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.operadores.pageSize);
  const error = useSelector((state) => state.operadores.error);
  const showModal = useSelector((state) => state.operadores.showModal);

  useEffect(() => {
    dispatch(
      getOperadores({
        pageSize,
        page: 1,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
  }, [dispatch, pageSize]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarOperador />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="operadores" />
        ) : (
          <ListaOperadores />
        )}
      </Grid>
      {showModal && <NuevoOperador />}
    </Grid>
  );
};
