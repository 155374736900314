import produce from "immer";
import * as types from "../types";

const initialState = {
  proveedores: [],
  proveedoresExportar: [],
  proveedor: {},
  isLoading: false,
  error: null,
  page: 1,
  pageSize: 25,
  pageMax: false,
  dataBuscar: {},
  showModal: false,
  editMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROVEEDORES:
    case types.GET_PROVEEDORES_EXPORTAR:
    case types.GET_PROVEEDOR:
    case types.CREATE_PROVEEDOR:
    case types.UPDATE_PROVEEDOR:
    case types.DELETE_PROVEEDOR:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case types.GET_PROVEEDORES_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.proveedores = action.payload.proveedores;
        draft.pageMax = action.payload.next === null;
        draft.error = null;
        draft.dataBuscar = action.payload.dataBuscar
          ? action.payload.dataBuscar
          : {};
        if (action.payload.buscando) draft.page = 1;
      });
    case types.PROVEEDORES_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page + 1;
      });
    case types.PROVEEDORES_PREV_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page - 1;
      });
    case types.PROVEEDORES_CHANGE_PAGE_SIZE:
      return produce(state, (draft) => {
        draft.page = 1;
        draft.pageSize = action.payload;
      });
    case types.PROVEEDORES_SHOW_MODAL:
      return produce(state, (draft) => {
        draft.showModal = true;
      });
    case types.PROVEEDORES_HIDE_MODAL:
      return produce(state, (draft) => {
        draft.showModal = false;
        draft.editMode = false;
        draft.proveedor = {};
        draft.error = null;
      });
    case types.CREATE_PROVEEDOR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.showModal = false;
      });
    case types.GET_PROVEEDOR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = true;
        draft.showModal = true;
        draft.proveedor = action.payload;
      });
    case types.UPDATE_PROVEEDOR_SUCCESS:
    case types.DELETE_PROVEEDOR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = false;
        draft.proveedor = {};
        draft.showModal = false;
      });
    case types.PROVEEDORES_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = action.payload ? action.payload : "offline";
      });
    case types.PROVEEDORES_SET_EXPORTANDO:
      return produce(state, (draft) => {
        draft.exportando = action.payload;
      });
    case types.GET_PROVEEDORES_EXPORTAR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        // draft.showModal = false;
        draft.proveedoresExportar = action.payload.proveedoresExportar;
        draft.error = null;
        draft.exportando = true;
      });
    default:
      return state;
  }
};
