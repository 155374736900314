import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProveedores } from "../../store/actions/proveedores";
import { BuscarProveedor } from "./BuscarProveedor";
import { ListaProveedores } from "./ListaProveedores";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from '@material-ui/core/Grid';
import { NuevoProveedor } from "./NuevoProveedor";



export const Proveedores = () => {
  // const [, dispatch] = useContext(ProveedoresContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.proveedores.pageSize);
  const error = useSelector((state) => state.proveedores.error);
  const showModal = useSelector((state) => state.proveedores.showModal);

  useEffect(() => {
    dispatch(
      getProveedores({
        pageSize,
        page: 1,
        buscando: true,
      })
    );
  }, [dispatch, pageSize]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarProveedor />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="proveedores" />
        ) : (
          <ListaProveedores />
        )}
      </Grid>
      {showModal && <NuevoProveedor />}
    </Grid>
  );
};
