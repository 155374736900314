import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getAccesorios,
  showModal,
  getAccesoriosExportar,
} from "../../store/actions/accesorios";
import { XlsExport } from "../../components/XlsExport";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputField from "../../components/InputField";

export const BuscarAccesorio = () => {
  const pageSize = useSelector((state) => state.accesorios.pageSize);
  const isLoading = useSelector((state) => state.accesorios.isLoading);
  const exportando = useSelector((state) => state.accesorios.exportando);

  const dispatch = useDispatch();

  const initialValues = {
    empresa: "",
    estatus: "activo",
    search: "",
  };

  const { control, handleSubmit, reset, getValues, errors } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    dispatch(
      getAccesorios({ page: 1, pageSize, dataBuscar: data, buscando: true })
    );
  };

  const onCleanSearch = (e) => {
    dispatch(
      getAccesorios({
        page: 1,
        pageSize,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
    reset(initialValues);
  };

  const onExportar = () => {
    const dataBuscar = getValues();
    dispatch(getAccesoriosExportar({ dataBuscar }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <InputField
              label="Empresa"
              name="empresa"
              select
              options={[
                { label: "", value: "" },
                { label: "Decoin Maquinaria", value: "decmaq" },
                { label: "Decoin", value: "decoin" },
              ]}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <InputField
              label="Estatus"
              name="estatus"
              select
              options={[
                { label: "", value: "" },
                { label: "Activo", value: "activo" },
                { label: "Inactivo", value: "inactivo" },
              ]}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              label="Busqueda"
              name="search"
              type="text"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => onCleanSearch(e)}
            >
              Limpiar Busqueda
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(showModal())}
            >
              Nuevo Accesorio
            </Button>{" "}
            <Button variant="outlined" color="primary" onClick={onExportar}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </form>
      {exportando && <XlsExport title="Accesorios" />}
    </>
  );
};
