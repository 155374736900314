import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "proveedores/";

export const getProveedores =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_PROVEEDORES });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_PROVEEDORES_SUCCESS,
        payload: {
          proveedores: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.PROVEEDORES_NEXT_PAGE });
      dispatch(getProveedores({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.PROVEEDORES_PREV_PAGE });
      dispatch(getProveedores({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.PROVEEDORES_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getProveedores({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.PROVEEDORES_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.PROVEEDORES_HIDE_MODAL });
  };
};

export const createProveedor = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_PROVEEDOR });
      await axios.post(url, data);
      dispatch({ type: types.CREATE_PROVEEDOR_SUCCESS });
      dispatch(
        getProveedores({
          page: 1,
          pageSize,
          buscando: true,
        })
      );
    } catch (error) {
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getProveedor = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_PROVEEDOR });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_PROVEEDOR_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateProveedor = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_PROVEEDOR });
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_PROVEEDOR_SUCCESS });
      dispatch(getProveedores({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteProveedor = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_PROVEEDOR });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_PROVEEDOR_SUCCESS });
      dispatch(getProveedores({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const proveedoresSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.PROVEEDORES_SET_EXPORTANDO, payload: exportar });
  };
};

export const getProveedoresExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_PROVEEDORES_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_PROVEEDORES_EXPORTAR_SUCCESS,
        payload: {
          proveedoresExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.PROVEEDORES_ERROR, payload: error.response });
    }
  };
