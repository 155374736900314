import produce from "immer";
import * as types from "../types";

const initialState = {
  maquinas: [],
  maquinasExportar: [],
  maquina: {},
  isLoading: false,
  error: null,
  page: 1,
  pageSize: 25,
  pageMax: false,
  dataBuscar: {},
  showModal: false,
  editMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MAQUINAS:
    case types.GET_MAQUINAS_EXPORTAR:
    case types.GET_MAQUINA:
    case types.CREATE_MAQUINA:
    case types.UPDATE_MAQUINA:
    case types.DELETE_MAQUINA:
    case types.TOGGLE_MAQUINA_ESTATUS:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case types.GET_MAQUINAS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.maquinas = action.payload.maquinas;
        draft.pageMax = action.payload.next === null;
        draft.error = null;
        draft.dataBuscar = action.payload.dataBuscar
          ? action.payload.dataBuscar
          : {};
        if (action.payload.buscando) draft.page = 1;
      });
    case types.MAQUINAS_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page + 1;
      });
    case types.MAQUINAS_PREV_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page - 1;
      });
    case types.MAQUINAS_CHANGE_PAGE_SIZE:
      return produce(state, (draft) => {
        draft.page = 1;
        draft.pageSize = action.payload;
      });
    case types.MAQUINAS_SHOW_MODAL:
      return produce(state, (draft) => {
        draft.showModal = true;
      });
    case types.MAQUINAS_HIDE_MODAL:
      return produce(state, (draft) => {
        draft.showModal = false;
        draft.editMode = false;
        draft.maquina = {};
        draft.error = null;
      });
    case types.CREATE_MAQUINA_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.showModal = false;
      });
    case types.GET_MAQUINA_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = true;
        draft.showModal = true;
        draft.maquina = action.payload;
      });
    case types.UPDATE_MAQUINA_SUCCESS:
    case types.DELETE_MAQUINA_SUCCESS:
    case types.TOGGLE_MAQUINA_ESTATUS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = false;
        draft.maquina = {};
        draft.showModal = false;
      });
    case types.MAQUINAS_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = action.payload ? action.payload : "offline";
      });
    case types.MAQUINAS_SET_EXPORTANDO:
      return produce(state, (draft) => {
        draft.exportando = action.payload;
      });
    case types.GET_MAQUINAS_EXPORTAR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        // draft.showModal = false;
        draft.maquinasExportar = action.payload.maquinasExportar;
        draft.error = null;
        draft.exportando = true;
      });
    default:
      return state;
  }
};
