import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getOperadores,
  showModal,
  getOperadoresExportar,
} from "../../store/actions/operadores";
import { XlsExport } from "../../components/XlsExport";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputField from "../../components/InputField";

export const BuscarOperador = () => {
  const pageSize = useSelector((state) => state.operadores.pageSize);
  const isLoading = useSelector((state) => state.operadores.isLoading);
  const exportando = useSelector((state) => state.operadores.exportando);

  const dispatch = useDispatch();

  const initialValues = {
    estatus: "activo",
    tipo: "",
    search: "",
  };

  const { control, handleSubmit, reset, getValues, errors } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      getOperadores({ page: 1, pageSize, dataBuscar: data, buscando: true })
    );
  };

  const onCleanSearch = (e) => {
    dispatch(
      getOperadores({
        page: 1,
        pageSize,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
    reset(initialValues);
  };

  const onExportar = () => {
    const dataBuscar = getValues();
    dispatch(getOperadoresExportar({ dataBuscar }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              label="Busqueda"
              name="search"
              type="text"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <InputField
              label="Tipo"
              name="tipo"
              select
              options={[
                { label: "", value: "" },
                { label: "Excavadora", value: "excavadora" },
                { label: "Retro", value: "retro" },
              ]}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <InputField
              label="Estatus"
              name="estatus"
              select
              options={[
                { label: "", value: "" },
                { label: "Activo", value: "activo" },
                { label: "Inactivo", value: "inactivo" },
              ]}
              control={control}
              errors={errors}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => onCleanSearch(e)}
            >
              Limpiar Busqueda
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(showModal())}
            >
              Nuevo Operador
            </Button>{" "}
            <Button variant="outlined" color="primary" onClick={onExportar}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </form>
      {exportando && <XlsExport title="Operadores" />}
    </>
  );
};
