import { combineReducers } from "redux";
import maquinas from "./maquinas";
import diesel from "./diesel";
import clientes from "./clientes";
import proveedores from "./proveedores";
import operadores from "./operadores";
import obras from "./obras";
import accesorios from "./accesorios";
import traslados from "./traslados";
import auth from "./auth";

export default combineReducers({
  maquinas,
  diesel,
  clientes,
  proveedores,
  operadores,
  obras,
  accesorios,
  traslados,
  auth,
});
