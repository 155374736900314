import * as yup from "yup";
// import { patterns } from "../../components/InputField";

export const defaultVal = () => ({
  nombre: "",
  rfc: "",
  notas: "",
  particular: 'no',
});

export const validation = () =>
  yup.object({
    nombre: yup.string().required("Requerido"),
    particular: yup.string().required("Requerido"),
  });

export const proveedoresForm = () => [
  {
    props: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      uppercase: true,
      autoFocus: true,
    },
    width: 10,
  },
  {
    props: {
      name: "particular",
      label: "Particular",
      type: "text",
      select: true,
      options: [
        {label: 'No', value: 'no'},
        {label: 'Si', value: 'si'},
      ]
    },
    width: 2,
  },
  {
    props: {
      name: "rfc",
      label: "RFC",
      type: "text",
      uppercase: true,
    },
    width: 3,
  },
  {
    props: {
      name: "notas",
      label: "Notas",
      type: "text",
      uppercase: true,
    },
    width: 9,
  },
];
