export const xlsDiesel = () => [
  { key: "fecha", header: "Fecha", tipo: "fecha", width: 80 },
  { key: "maquina.nombre", header: "Maquina" },
  { key: "maquina.tipo", header: "Tipo" },
  { key: "maquina.empresa", header: "Empresa" },
  { key: "litros", header: "Litros", total: true },
  { key: "horometroAnterior", header: "Horometro Anterior" },
  { key: "horometro", header: "Horometro" },
  { key: "comentarios", header: "Comentarios" },
  { key: "horasReales", header: "Horas Reales", tipo: "number" },
  { key: "rendimiento", header: "Rendimiento", tipo: "number" },
];

export const xlsMaquinas = () => [
  { key: "nombre", header: "Nombre" },
  { key: "tipo", header: "Tipo" },
  { key: "empresa", header: "Empresa" },
  { key: "marca", header: "Marca" },
  { key: "modelo", header: "Modelo" },
  { key: "year", header: "Año" },
  { key: "fechaCompra", header: "Fecha Compra", tipo: "fecha" },
  { key: "serie", header: "Serie" },
  { key: "horometro", header: "Horometro" },
  { key: "capacidadDiesel", header: "Capacidad Diesel" },
];

export const xlsCLientes = () => [
  { key: "nombre", header: "Nombre" },
  { key: "rfc", header: "RFC" },
  { key: "notas", header: "Notas" },
];

export const xlsProveedores = () => [
  { key: "nombre", header: "Nombre" },
  { key: "rfc", header: "RFC" },
  { key: "notas", header: "Notas" },
];

export const xlsOperadores = () => [
  { key: "nombre", header: "Nombre" },
  { key: "apellido", header: "Apellido" },
  { key: "tipo", header: "Tipo" },
];

export const xlsObras = () => [
  { key: "nombre", header: "Nombre" },
  { key: "notas", header: "Notas" },
];

export const xlsAccesorios = () => [
  { key: "nombre", header: "Nombre" },
  { key: "empresa", header: "Empresa" },
  { key: "marca", header: "Marca" },
  { key: "modelo", header: "Modelo" },
  { key: "year", header: "Año" },
  { key: "fechaCompra", header: "Fecha Compra", tipo: "fecha" },
  { key: "serie", header: "Serie" },
];

export const xlsTraslados = () => [
  { key: "fecha", header: "Fecha", tipo: "fecha", width: 80 },
  { key: "tipo", header: "Tipo" },
  { key: "maquina.nombre", header: "Maquina" },
  { key: "cliente.nombre", header: "Cliente" },
  { key: "obraOrigen.nombre", header: "Origen" },
  { key: "obraDestino.nombre", header: "Destino" },
  { key: "operador.busqueda", header: "Operador" },
  { key: "accesorio.nombre", header: "Accesorio" },
  { key: "proveedor.nombre", header: "Proveedor" },
  { key: "precioVenta", header: "Precio Venta" },
];
