import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  createMaquina,
  updateMaquina,
  deleteMaquina,
  toggleEstatus,
} from "../../store/actions/maquinas";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { fechaHoyIso } from "../../shared";
import { ErrorMessage } from "../../components/ErrorMessage";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStyles } from '../../lib/styles/nuevoStyles';

import {
  maquinasForm,
  defaultVal,
  validation,
} from "../../lib/forms/maquinasForm";

export const NuevaMaquina = () => {
  const showModal = useSelector((state) => state.maquinas.showModal);
  const page = useSelector((state) => state.maquinas.page);
  const pageSize = useSelector((state) => state.maquinas.pageSize);
  const dataBuscar = useSelector((state) => state.maquinas.dataBuscar);
  const maquina = useSelector((state) => state.maquinas.maquina);
  const editMode = useSelector((state) => state.maquinas.editMode);
  const isLoading = useSelector((state) => state.maquinas.isLoading);
  const error = useSelector((state) => state.maquinas.error);
  const dispatch = useDispatch();

  const classes = useStyles();

  const formModel = maquinasForm();
  const defaultValues = defaultVal();
  const validationSchema = validation();

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: editMode
      ? { ...maquina }
      : { ...defaultValues, fechaCompra: fechaHoyIso() },
  });

  const onDelete = () => {
    const id = maquina.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar la maquina ${maquina.nombre}?`
      )
    ) {
      dispatch(deleteMaquina({ id, page, pageSize, dataBuscar }));
    }
  };

  const onToggleEstatus = () => {
    const estatus = maquina.estatus === "activo" ? "inactivo" : "activo";
    const id = maquina.id;
    dispatch(toggleEstatus({ id, estatus, page, pageSize, dataBuscar }));
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      estatus: "activo",
      // fechaCompra: fechaToIso(data.fechaCompra),
    };
    console.log(data);
    const id = maquina.id;
    if (editMode) {
      dispatch(updateMaquina({ id, data, page, pageSize, dataBuscar }));
    } else {
      dispatch(createMaquina({ data, pageSize }));
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          dispatch(hideModal());
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editMode ? "Editar Maquina" : "Agregar Nueva Maquina"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid container spacing={2}>
              {formModel.map((input) => (
                <Grid item key={input.props.name} md={input.width} xs={12}>
                  <InputField
                    {...input.props}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          {error && <ErrorMessage module="maquinas" />}
        </DialogContent>
        <DialogActions>
          {editMode && (
            <Button onClick={onDelete} className={classes.warning}>
              Borrar Maquina
            </Button>
          )}
          {editMode && (
            <Button
              color="primary"
              onClick={onToggleEstatus}
              disabled={isLoading}
            >
              {maquina.estatus === "activo"
                ? "Desactivar Maquina"
                : "Activar Maquina"}
            </Button>
          )}
          <Button
            onClick={() => dispatch(hideModal())}
            color="primary"
            tabIndex={-1}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Editar" : "Capturar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
