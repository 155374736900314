import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTrasladosEstado,
  showModal,
  trasladosSetPretraslado,
} from "../../store/actions/traslados";
import { defaultVal } from "../../lib/forms/trasladoForm";
import { fechaHoyIso } from "../../shared";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import IconButton from "@material-ui/core/IconButton";
import CircleIcon from "@material-ui/icons/FiberManualRecord";

import {
  StyledTableCell as TableCell,
  StyledTableRow,
  useStyles,
} from "../../lib/styles/listaStyles";

export const EstadoTraslados = () => {
  const estado = useSelector((state) => state.traslados.estado);
  const operadores = useSelector((state) => state.operadores.operadores);
  const [operadoresEstatus, setOperadoresEstatus] = React.useState([]);

  const dispatch = useDispatch();

  const classes = useStyles();

  React.useEffect(() => {
    dispatch(getTrasladosEstado());
  }, [dispatch]);

  React.useEffect(() => {
    const opEstatus = [];
    operadores.forEach((operador) => {
      const found = estado.find(
        (mov) => mov.operador?.id === operador.id && mov.activa
      );
      if (!found) opEstatus.push(operador);
    });
    setOperadoresEstatus(opEstatus);
  }, [estado, operadores]);

  console.log(operadoresEstatus);

  const onMovimiento = (
    id,
    maquina,
    obraDestino,
    cambioOperador,
    activa,
    en_reparacion
  ) => {
    dispatch(
      trasladosSetPretraslado({
        ...defaultVal,
        maquina,
        obraOrigen: obraDestino,
        tipo: cambioOperador ? "cambioOperador" : "movimiento",
        fecha: fechaHoyIso(),
        precioVenta: 0,
        activa,
        en_reparacion,
        id,
      })
    );
    dispatch(showModal());
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9}>
          <TableContainer component={Paper}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Estado</TableCell>
                  <TableCell>Maquina</TableCell>
                  <TableCell>Ubicacion</TableCell>
                  <TableCell>Operador</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Accesorio</TableCell>
                  <TableCell className={classes.tableCell}>
                    <IconButton size="small">
                      <TrendingFlatIcon
                        style={{ fontSize: 18, color: "white" }}
                      />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <IconButton size="small">
                      <TransferWithinAStationIcon
                        style={{ fontSize: 18, color: "white" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {estado.map((doc) => (
                  <StyledTableRow key={doc.id} hover className={classes.hover}>
                    <TableCell className={classes.tableCell}>
                      <CircleIcon
                        style={{
                          color: doc.en_reparacion
                            ? "#e53935"
                            : doc.activa
                            ? "#4caf50"
                            : "#ffc107",
                        }}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {doc.maquina.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {doc.obraDestino?.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {doc.operador?.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {doc.cliente?.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {doc.accesorio?.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          onMovimiento(
                            doc.id,
                            doc.maquina,
                            doc.obraDestino.nombre,
                            false,
                            doc.activa,
                            doc.en_reparacion
                          )
                        }
                      >
                        <TrendingFlatIcon
                          style={{ fontSize: 18 }}
                          color="primary"
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          onMovimiento(
                            doc.id,
                            doc.maquina,
                            doc.obraDestino.nombre,
                            true,
                            doc.activa,
                            doc.en_reparacion
                          )
                        }
                      >
                        <TransferWithinAStationIcon
                          style={{ fontSize: 18 }}
                          color="primary"
                        />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} lg={3}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Operadores Disponibles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {operadoresEstatus.map((doc) => (
                  <StyledTableRow key={doc.id} hover className={classes.hover}>
                    <TableCell className={classes.tableCell}>
                      {doc.busqueda}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter></TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
