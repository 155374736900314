import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMaquina } from "../../store/actions/maquinas";

import { Lista } from "../../components/Lista";
import { maquinasTable } from "../../lib/tables/maquinasTable";

export const ListaMaquinas = () => {
  const maquinas = useSelector((state) => state.maquinas.maquinas);

  const dispatch = useDispatch();

  const listaModel = maquinasTable();

  const onUpdate = (id) => {
    dispatch(getMaquina(id));
  };

  return (
    <Lista
      module="maquinas"
      docs={maquinas}
      onUpdate={onUpdate}
      listaModel={listaModel}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="maquinas" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Nombre</TableCell>
  //           <TableCell>Tipo</TableCell>
  //           <TableCell>Empresa</TableCell>
  //           <TableCell>Marca</TableCell>
  //           <TableCell>Modelo</TableCell>
  //           <TableCell>Año</TableCell>
  //           <TableCell>Horometro</TableCell>
  //           <TableCell>Capacidad</TableCell>
  //           <TableCell>Serie</TableCell>
  //           <TableCell>Fecha Compra</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {maquinas.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>
  //               {doc.nombre}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.tipo === "excavadora" ? "Excavadora" : "Retro"}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.empresa === "decmaq" ? "Maquinaria" : "Decoin"}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.marca}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.modelo}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.year}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.horometro}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.capacidadDiesel}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.serie}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.fechaCompra}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
