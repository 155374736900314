import * as yup from "yup";
// import { patterns } from "../../components/InputField";

export const defaultVal = () => ({
  nombre: "",
  rfc: "",
  notas: "",
});

export const validation = () =>
  yup.object({
    nombre: yup.string().required("Requerido"),
  });

export const clientesForm = () => [
  {
    props: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      uppercase: true,
      autoFocus: true,
    },
    width: 12,
  },
  {
    props: {
      name: "rfc",
      label: "RFC",
      type: "text",
      uppercase: true,
    },
    width: 3,
  },
  {
    props: {
      name: "notas",
      label: "Notas",
      type: "text",
      uppercase: true,
    },
    width: 9,
  },
];
