import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Lista } from "../../components/Lista";
import { dieselTable } from "../../lib/tables/dieselTable";
import { getReporteDiesel } from "../../store/actions/diesel";

export const ListaDiesel = () => {
  const reportesDiesel = useSelector((state) => state.diesel.reportesDiesel);

  const dispatch = useDispatch();

  const onUpdate = (id) => {
    dispatch(getReporteDiesel(id));
  };

  return (
    <Lista
      module="diesel"
      docs={reportesDiesel}
      onUpdate={onUpdate}
      listaModel={dieselTable()}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="diesel" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Fecha</TableCell>
  //           <TableCell>Maquina</TableCell>
  //           <TableCell>Litros</TableCell>
  //           <TableCell>Horometro</TableCell>
  //           <TableCell>Horas Reales</TableCell>
  //           <TableCell>Rendimiento</TableCell>
  //           <TableCell>Comentarios</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {reportesDiesel.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>
  //               {doc.fecha}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.maquina.nombre}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.litros}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.horometro}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.horasReales}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.rendimiento}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.comentarios}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
