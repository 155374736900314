import * as yup from "yup";
// import { patterns } from "../../components/InputField";

export const defaultVal = () => ({
  fecha: "",
  tipo: "movimiento",
  maquina: "",
  obraOrigen: "",
  obraDestino: "",
  cliente: "",
  operador: "",
  accesorio: "",
  proveedor: "",
  precioVenta: 0,
});

const idRequerido = yup
  .object()
  .shape({
    id: yup.number().required("Requerido"),
  })
  .required("Requerido");

export const validation = () =>
  yup.object().shape({
    fecha: yup.string().required("Requerido"),
    tipo: yup.string().required("Requerido"),
    precioVenta: yup
      .number()
      .required("Requerido")
      .typeError("Numero invalido"),
    maquina: idRequerido.nullable(true),
    cliente: yup
      .object()
      .when(["tipo", "obraDestino.taller"], {
        is: (tipo, taller) => tipo !== "cambioOperador" && !taller,
        then: idRequerido,
      })
      .nullable(true),
    proveedor: yup
      .object()
      .when("tipo", {
        is: (tipo) => tipo !== "cambioOperador",
        then: idRequerido,
      })
      .nullable(true),
    obraDestino: yup
      .object()
      .when("tipo", {
        is: (tipo) => tipo !== "cambioOperador",
        then: idRequerido,
      })
      .nullable(true),
    operador: yup
      .object()
      // .when(["tipo", "obraDestino.taller"], {
      //   is: (tipo, taller) =>
      //     (tipo !== "cambioOperador" && !taller) || tipo === "cambioOperador",
      //   then: idRequerido,
      // })
      .when("tipo", {
        is: (tipo) => tipo === "cambioOperador",
        then: idRequerido,
      })
      .nullable(true),
    // .when("obraDestino.taller", {
    //   is: false,
    //   then: yup
    //     .object()
    //     .shape({
    //       id: yup.number().required("Requerido"),
    //     })
    //     .required("Requerido")
    //     .nullable(true),
    // }),
    // maquina: yup.string().required("Requerido").nullable(true),
    // proveedor: yup.string().required("Requerido").nullable(true),
  });

//corregir bug en api de traslados para que se apliquen los mismos criterios al actualizar que al crear

export const trasladoForm = () => [
  {
    props: {
      name: "fecha",
      label: "Fecha",
      type: "date",
    },
    width: 3,
  },
  {
    props: {
      name: "tipo",
      label: "Tipo",
      select: true,
      options: [
        { label: "Movimiento", value: "movimiento" },
        { label: "Cambio Operador", value: "cambioOperador" },
      ],
    },
    width: 3,
  },
  {
    props: {
      name: "maquina",
      label: "Maquina",
      autoComplete: true,
      acLabel: "nombre",
    },
    width: 6,
  }, //nueva fila
  {
    props: {
      name: "obraOrigen",
      label: "Origen",
      type: "text",
      disabled: true,
    },
    width: 6,
  },
  {
    props: {
      name: "obraDestino",
      label: "Destino",
      autoComplete: true,
      acLabel: "nombre",
    },
    width: 6,
  },
  {
    props: {
      name: "cliente",
      label: "Cliente",
      autoComplete: true,
      acLabel: "nombre",
    },
    width: 6,
  },
  {
    props: {
      name: "operador",
      label: "Operador",
      autoComplete: true,
      acLabel: "busqueda",
    },
    width: 6,
  },
  {
    props: {
      name: "accesorio",
      label: "Accesorio",
      autoComplete: true,
      acLabel: "nombre",
    },
    width: 6,
  },
  {
    props: {
      name: "proveedor",
      label: "Proveedor",
      autoComplete: true,
      acLabel: "nombre",
    },
    width: 6,
  },
  {
    props: {
      name: "precioVenta",
      label: "Precio Venta",
      type: "number",
    },
    width: 3,
  },
];
