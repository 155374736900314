import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  createReporteDiesel,
  updateReporteDiesel,
  deleteReporteDiesel,
} from "../../store/actions/diesel";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {
  fechaToIso,
  fechasDiesel,
  addDaysToDate,
  diaDeLaSemana,
  isoToDate,
} from "../../shared";
import { ErrorMessage } from "../../components/ErrorMessage";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useStyles } from '../../lib/styles/nuevoStyles';

import { validation } from "../../lib/forms/dieselForm";

export const NuevoReporteDiesel = () => {
  const showModal = useSelector((state) => state.diesel.showModal);
  const page = useSelector((state) => state.diesel.page);
  const pageSize = useSelector((state) => state.diesel.pageSize);
  const dataBuscar = useSelector((state) => state.diesel.dataBuscar);
  const maquinas = useSelector((state) => state.maquinas.maquinas);
  const reporteDiesel = useSelector((state) => state.diesel.reporteDiesel);
  const editMode = useSelector((state) => state.diesel.editMode);
  const isLoading = useSelector((state) => state.diesel.isLoading);
  const error = useSelector((state) => state.diesel.error);

  const dispatch = useDispatch();

  const classes = useStyles();

  const litrosInput = useRef();

  const validationSchema = validation();

  const { fechaFinal, fechaInicial } = fechasDiesel();
  const [isFormReady, setIsFormReady] = useState(false);
  const [formFechaFinal, setFormFechaFinal] = useState(fechaToIso(fechaFinal));
  const [formFechaInicial, setFormFechaInicial] = useState(
    fechaToIso(fechaInicial)
  );
  const [maquinaIndex, setMaquinaIndex] = useState(0);
  const [fechaIndex, setFechaIndex] = useState(0);

  const getInitialValues = () => {
    return {
      fecha: formFechaInicial,
      maquina: maquinas[0].id,
      litros: "",
      horometro: "",
      comentarios: "",
    };
  };

  const reporteValues = editMode
    ? {
        ...reporteDiesel,
        maquina: reporteDiesel.maquina.id,
      }
    : {};

  const { control, handleSubmit, errors, reset, setValue, getValues } = useForm(
    {
      resolver: yupResolver(validationSchema),
      mode: "onChange",
      defaultValues: editMode ? reporteValues : getInitialValues(),
    }
  );

  useEffect(() => {
    if (isFormReady) {
      reset({
        fecha: formFechaInicial,
        maquina: maquinas[0].id,
      });
      // console.log(litrosInput);
      litrosInput.current.focus();
    }
  }, [isFormReady, reset, formFechaInicial, maquinas]);

  const submitCycle = () => {
    if (fechaIndex < 6) {
      const indexDown = fechaIndex === 6 ? 6 : fechaIndex + 1;
      setFechaIndex(indexDown);
      reset({
        ...getInitialValues(),
        fecha: fechaToIso(
          addDaysToDate(isoToDate(formFechaInicial), indexDown)
        ),
        maquina: getValues("maquina"),
      });
    } else if (maquinaIndex < maquinas.length - 1) {
      const indexRight =
        maquinaIndex === maquinas.length - 1 ? maquinaIndex : maquinaIndex + 1;
      setMaquinaIndex(indexRight);
      setFechaIndex(0);
      reset({
        ...getInitialValues(),
        fecha: formFechaInicial,
        maquina: maquinas[indexRight].id,
      });
    } else {
      reset({
        ...getInitialValues(),
        fecha: getValues("fecha"),
        maquina: getValues("maquina"),
      });
    }
  };

  const onDelete = () => {
    const id = reporteDiesel.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar el reporte con fecha ${reporteDiesel.fecha} de la maquina ${reporteDiesel.maquina.nombre} ?`
      )
    ) {
      dispatch(deleteReporteDiesel({ id, page, pageSize, dataBuscar }));
    }
  };

  const onSubmit = (data) => {
    const updatedData = {
      ...data,
      // fecha: fechaToIso(data.fecha),
      maquina: +data.maquina,
      comentarios: data.comentarios.toUpperCase(),
    };

    if (!editMode) {
      submitCycle();
      litrosInput.current.focus();
    }

    console.log(updatedData);

    const id = reporteDiesel.id;
    if (editMode) {
      dispatch(
        updateReporteDiesel({
          id,
          data: updatedData,
          page,
          pageSize,
          dataBuscar,
        })
      );
    } else {
      dispatch(createReporteDiesel({ data: updatedData, pageSize }));
    }
  };

  const onSubmitFechas = (e) => {
    e.preventDefault();
    setIsFormReady(true);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 38 && !editMode) {
      e.preventDefault();
      const indexUp = fechaIndex === 0 ? 0 : fechaIndex - 1;
      setFechaIndex(indexUp);
      setValue(
        "fecha",
        fechaToIso(addDaysToDate(isoToDate(formFechaInicial), indexUp))
      );
    }
    if (e.keyCode === 40 && !editMode) {
      e.preventDefault();
      const indexDown = fechaIndex === 6 ? 6 : fechaIndex + 1;
      setFechaIndex(indexDown);
      setValue(
        "fecha",
        fechaToIso(addDaysToDate(isoToDate(formFechaInicial), indexDown))
      );
    }
    if (e.keyCode === 37 && !editMode) {
      e.preventDefault();
      const indexLeft = maquinaIndex === 0 ? 0 : maquinaIndex - 1;
      setMaquinaIndex(indexLeft);
      setFechaIndex(0);
      setValue("maquina", maquinas[indexLeft].id);
      setValue("fecha", formFechaInicial);
    }
    if (e.keyCode === 39 && !editMode) {
      e.preventDefault();
      const indexRight =
        maquinaIndex === maquinas.length - 1 ? maquinaIndex : maquinaIndex + 1;
      setMaquinaIndex(indexRight);
      setFechaIndex(0);
      setValue("maquina", maquinas[indexRight].id);
      setValue("fecha", formFechaInicial);
    }
    if (
      e.keyCode === 13 &&
      getValues("litros") === "" &&
      getValues("horometro") === "" &&
      !editMode
    ) {
      e.preventDefault();
      submitCycle();
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          dispatch(hideModal());
        }
      }}
      fullWidth
      maxWidth="sm"
    >
      {!isFormReady && !editMode ? (
        <form
          onSubmit={(e) => {
            onSubmitFechas(e);
          }}
        >
          <DialogTitle>Seleccionar rango de fechas</DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="date"
                    label="Fecha Inicial"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formFechaInicial}
                    onChange={(e) => setFormFechaInicial(e.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="Fecha Final"
                    value={formFechaFinal}
                    onChange={(e) => setFormFechaFinal(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => dispatch(hideModal())}>
              Cancelar
            </Button>
            <Button color="primary" type="submit">
              Siguiente
            </Button>
          </DialogActions>
        </form>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            onKeyDown(e);
          }}
        >
          <DialogTitle>
            {editMode ? "Editar Reporte" : "Agregar Reportes"}
          </DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <InputField
                    control={control}
                    errors={errors}
                    name="fecha"
                    type="date"
                    label="Fecha"
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SwapVertIcon />
                          {diaDeLaSemana(isoToDate(getValues("fecha")))}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    control={control}
                    errors={errors}
                    name="maquina"
                    select
                    label="Maquina"
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SwapHorizIcon />
                        </InputAdornment>
                      ),
                    }}
                    options={maquinas.map((maquina) => ({
                      value: maquina.id,
                      label: maquina.nombre,
                    }))}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    control={control}
                    errors={errors}
                    type="text"
                    name="litros"
                    label="Litros"
                    inputRef={litrosInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    control={control}
                    errors={errors}
                    type="text"
                    name="horometro"
                    label="Horometro"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    control={control}
                    errors={errors}
                    type="text"
                    name="comentarios"
                    label="Comentarios"
                    uppercase
                  />
                </Grid>
              </Grid>
            </div>

            {error && <ErrorMessage module="diesel" />}
          </DialogContent>
          <DialogActions>
            {editMode && (
              <Button
                className={classes.warning}
                onClick={onDelete}
                disabled={isLoading}
              >
                Borrar Reporte
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => dispatch(hideModal())}
              tabIndex="-1"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={isLoading}>
              {editMode ? "Editar" : "Capturar"}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};
