import produce from 'immer';
import * as types from '../types';

const initialState = {
    isAuth: false,
    user: {},
    isLoading: false,
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN:
        case types.LOGOUT:
        case types.GET_USER:
            return produce(state, (draft) => {
                draft.isLoading = true;
            });
        case types.LOGIN_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = null;
                draft.isAuth = true;
                draft.user = action.payload;
            });
        case types.LOGIN_FAIL:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.isAuth = false;
                draft.user = {};
            });
        case types.LOGOUT_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = null;
                draft.isAuth = false;
                draft.user = {};
            });
        case types.AUTH_ERROR:
            return produce(state, (draft) => {
                draft.error = action.payload ? action.payload : 'offline';
                draft.isLoading = false;
            });
        default:
            return state;
    }
}