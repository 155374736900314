import React from "react";
import { useSelector } from "react-redux";

export const ErrorMessage = ({ module }) => {
  const error = useSelector((state) => state[module].error);

  const getMessage = (error) => {
    if (error === "offline")
      return "No se pudo establecer conexion con el servidor.";
    if (error === "horometro invalido")
      return "El horometro actual no puede ser menor al horometro anterior.";
    if (error === "horometro invalido diferencia")
      return "El horometro actual no puede tenere una diferencia de mas de 50 horas contra el horometro anterior.";
    if (error.status === 401 && module === "auth")
      return "Usuario o contraseña incorrecto.";
    if (error.status === 401)
      return (
        <span>
          Se ha cerrado la sesion. Favor de <a href="/">Iniciar Sesion</a>
        </span>
      );
    if (error.status === 403)
      return "No se tiene permiso para realizar esta accion.";
    if (error.status === 400 && error.data.nombre) return error.data.nombre[0];
    if (error.status === 400 && error.data.litros) return error.data.litros[0];
    if (error.status === 400 && error.data.horometro)
      return error.data.horometro[0];
  };

  return (
    <div className="alert alert-danger mt-2" role="alert">
      {getMessage(error)}
    </div>
  );
};
