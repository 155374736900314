import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getClientes,
  showModal,
  getClientesExportar,
} from "../../store/actions/clientes";
import { XlsExport } from "../../components/XlsExport";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputField from "../../components/InputField";

export const BuscarCliente = () => {
  const pageSize = useSelector((state) => state.clientes.pageSize);
  const isLoading = useSelector((state) => state.clientes.isLoading);
  const exportando = useSelector((state) => state.clientes.exportando);

  const dispatch = useDispatch();

  const initialValues = {
    search: "",
  };

  const { control, handleSubmit, reset, getValues, errors } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      getClientes({ page: 1, pageSize, dataBuscar: data, buscando: true })
    );
  };

  const onCleanSearch = (e) => {
    dispatch(
      getClientes({
        page: 1,
        pageSize,
        buscando: true,
      })
    );
    reset(initialValues);
  };

  const onExportar = () => {
    const dataBuscar = getValues();
    dispatch(getClientesExportar({ dataBuscar }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              label="Busqueda"
              name="search"
              type="text"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => onCleanSearch(e)}
            >
              Limpiar Busqueda
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(showModal())}
            >
              Nuevo Cliente
            </Button>{" "}
            <Button variant="outlined" color="primary" onClick={onExportar}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </form>
      {exportando && <XlsExport title="Clientes" />}
    </>
  );
};
