import React from "react";
import {
  NavLink as RouterNavLink,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/actions/auth";
// import { version } from "../../package.json";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { makeStyles, useTheme } from "@material-ui/core/styles";

const drawerWidth = 180;

const useStyles = makeStyles((theme) => {
  // const accentColor = Color(palette.primary.main)
  //   .lighten(1.75)
  //   .fade(0.6)
  //   .toString();

  return {
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("md")]: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
        zIndex: theme.zIndex.drawer + 1,
      },
      backgroundColor: theme.palette.grey.A400,
      color: theme.palette.common.white,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(5),
    },
    title: {
      flexGrow: 1,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    avatar: {
      height: theme.spacing(4),
      width: theme.spacing(4),
      marginRight: 10,
      cursor: "pointer",
    },
    selected: {
      paddingLeft: theme.spacing(4),
      "&.Mui-selected": {
        fontWeight: 500,
        backgroundColor: "white",
        color: theme.palette.primary.main,
        // color: "yellow",
      },
    },
  };
});

export default function Layout({ children, window }) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [catalogos, setCatalogos] = React.useState(true);
  const [operacion, setOperacion] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isAuth = useSelector((state) => state.auth.isAuth);
  // const isLoading = useSelector((state) => state.auth.isLoading);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  // const router = useRouter();
  // const history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} style={{ minHeight: 48 }} />
      <Divider />
      <List>
        <ListItem button onClick={() => setOperacion(!operacion)}>
          <ListItemText>
            <b>Operacion</b>
          </ListItemText>
          {operacion ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={operacion} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListNavLink to="/diesel" label="Diesel" />
            <ListNavLink to="/traslados" label="Traslados" />
          </List>
        </Collapse>
        <ListItem button onClick={() => setCatalogos(!catalogos)}>
          <ListItemText>
            <b>Catalogos</b>
          </ListItemText>
          {catalogos ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={catalogos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListNavLink to="/maquinas" label="Maquinas" />
            <ListNavLink to="/operadores" label="Operadores" />
            <ListNavLink to="/clientes" label="Clientes" />
            <ListNavLink to="/proveedores" label="Proveedores" />
            <ListNavLink to="/obras" label="Obras" />
            <ListNavLink to="/accesorios" label="Accesorios" />
          </List>
        </Collapse>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" component={RouterLink}>
            <Avatar
              src="/excavator.svg"
              className={classes.avatar}
              variant="square"
            />
          </Link>
          <Typography variant="h5" noWrap className={classes.title}>
            {" "}
            adminMaq
          </Typography>

          {isAuth && (
            <>
              <Typography>{user.razonSocial}</Typography>
              <IconButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {/* <Link href="/user" > */}
                <MenuItem
                  onClick={(e) => {
                    setAnchorEl(null);
                    e.preventDefault();
                  }}
                >
                  {user.name}
                </MenuItem>
                {/* </Link> */}
                {/* <Link href="/user/signout" passHref> */}
                <MenuItem
                  onClick={(e) => {
                    setAnchorEl(null);
                    onLogout(e);
                  }}
                >
                  Cerrar Sesion
                </MenuItem>
                {/* </Link> */}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
      {isAuth && (
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} style={{ minHeight: 40 }} />
        {children}
      </main>
    </div>
  );
}

function ListNavLink({ to, label }) {
  const location = useLocation();

  return (
    <ListItem
      button
      component={RouterNavLink}
      to={to}
      exact
      selected={location.pathname === to}
    >
      <ListItemText primary={label} />
    </ListItem>
  );
}
