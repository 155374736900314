import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccesorios } from "../../store/actions/accesorios";
import { BuscarAccesorio } from "./BuscarAccesorio";
import { ListaAccesorios } from "./ListaAccesorios";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from "@material-ui/core/Grid";
import { NuevoAccesorio } from "./NuevoAccesorio";

export const Accesorios = () => {
  // const [, dispatch] = useContext(AccesoriosContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.accesorios.pageSize);
  const error = useSelector((state) => state.accesorios.error);
  const showModal = useSelector((state) => state.accesorios.showModal);

  useEffect(() => {
    dispatch(
      getAccesorios({
        pageSize,
        page: 1,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
  }, [dispatch, pageSize]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarAccesorio />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="accesorios" />
        ) : (
          <ListaAccesorios />
        )}
      </Grid>
      {showModal && <NuevoAccesorio />}
    </Grid>
  );
};
