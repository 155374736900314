import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const PrivateRoute = ({ component, path, exact, redirect }) => {
    const isAuth = useSelector((state) => state.auth.isAuth);

    return !isAuth ?
        <Redirect to="/login" />
        :
        <Route path={path} component={component} exact={exact}>
            {redirect && <Redirect to={redirect} />}
        </Route>
}

