import React from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from "@material-ui/core/Grid";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export const Login = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    username: yup.string().required("Requerido"),
    password: yup.string().required("Requerido"),
  });

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "40vh" }}
    >
      <Grid item md={3} sm={5} xs={8}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Iniciar Sesion</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Email"
                name="username"
                type="text"
                control={control}
                errors={errors}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Password"
                name="password"
                type="password"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                Iniciar Sesion
              </Button>
            </Grid>
          </Grid>
        </form>
        {error && <ErrorMessage module="auth" />}
        {isAuth && <Redirect to="/maquinas" />}
      </Grid>
    </Grid>
    // <Container maxWidth="xs" justify="center">
    // </Container>
  );
};
