import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dieselSetExportando } from "../store/actions/diesel";
import { maquinasSetExportando } from "../store/actions/maquinas";
import { clientesSetExportando } from "../store/actions/clientes";
import { proveedoresSetExportando } from "../store/actions/proveedores";
import { operadoresSetExportando } from "../store/actions/operadores";
import { obrasSetExportando } from "../store/actions/obras";
import { accesoriosSetExportando } from "../store/actions/accesorios";
import { trasladosSetExportando } from "../store/actions/traslados";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { updateObject, toCurrency } from "../shared";
import {
  xlsDiesel,
  xlsMaquinas,
  xlsCLientes,
  xlsProveedores,
  xlsOperadores,
  xlsObras,
  xlsAccesorios,
  xlsTraslados,
} from "../reports";

export const XlsExport = (props) => {
  const maquinas = useSelector((state) => state.maquinas.maquinasExportar);
  const diesel = useSelector((state) => state.diesel.reportesDieselExportar);
  const clientes = useSelector((state) => state.clientes.clientesExportar);
  const obras = useSelector((state) => state.obras.obrasExportar);
  const traslados = useSelector((state) => state.traslados.trasladosExportar);
  const accesorios = useSelector(
    (state) => state.accesorios.accesoriosExportar
  );
  const operadores = useSelector(
    (state) => state.operadores.operadoresExportar
  );
  const proveedores = useSelector(
    (state) => state.proveedores.proveedoresExportar
  );

  const dispatch = useDispatch();

  const hoy = new Date();
  let dd = hoy.getDate();
  let mm = hoy.getMonth() + 1;
  let yyyy = hoy.getFullYear();
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd < 10) {
    dd = "0" + dd;
  }
  const yy = yyyy.toString().substr(2, 2);

  let docsData = [];
  let docs = [];
  let config = [];

  switch (props.title) {
    case "Maquinas":
      docs = maquinas;
      config = xlsMaquinas();
      break;
    case "Diesel":
      docs = diesel;
      config = xlsDiesel();
      break;
    case "Clientes":
      docs = clientes;
      config = xlsCLientes();
      break;
    case "Proveedores":
      docs = proveedores;
      config = xlsProveedores();
      break;
    case "Operadores":
      docs = operadores;
      config = xlsOperadores();
      break;
    case "Obras":
      docs = obras;
      config = xlsObras();
      break;
    case "Accesorios":
      docs = accesorios;
      config = xlsAccesorios();
      break;
    case "Traslados":
      docs = traslados;
      config = xlsTraslados();
      break;
    default:
      docs = [];
      config = [];
      break;
  }

  let totales = {};
  config.forEach((element) => {
    if (element.total) totales[element.key] = 0;
  });

  docs.forEach((doc) => {
    let updatedDoc = doc;

    if (props.title === "Maquinas" || props.title === "Accesorios") {
      updatedDoc = updateObject(updatedDoc, {
        fechaCompra: new Date(updatedDoc.fechaCompra + "T00:00:00-07:00"),
      });
    }
    if (props.title === "Diesel" || props.title === "Traslados") {
      updatedDoc = updateObject(updatedDoc, {
        fecha: new Date(updatedDoc.fecha + "T00:00:00-07:00"),
      });
      if (props.title === "Diesel") {
        updatedDoc = updateObject(updatedDoc, {
          horasReales: updatedDoc.horasReales ? +updatedDoc.horasReales : "",
          rendimiento: updatedDoc.rendimiento ? +updatedDoc.rendimiento : "",
        });
      }
    }

    for (let key in totales) {
      totales[key] = totales[key] + updatedDoc[key];
    }

    docsData.push(updatedDoc);
  });

  useEffect(() => {
    _exporter.save();
    dispatch(dieselSetExportando(false));
    dispatch(maquinasSetExportando(false));
    dispatch(clientesSetExportando(false));
    dispatch(proveedoresSetExportando(false));
    dispatch(operadoresSetExportando(false));
    dispatch(obrasSetExportando(false));
    dispatch(accesoriosSetExportando(false));
    dispatch(trasladosSetExportando(false));
  }, [dispatch]);

  let _exporter = useRef(null);

  return (
    <ExcelExport
      data={docsData}
      fileName={props.title + yy + mm + dd + ".xlsx"}
      ref={(exporter) => {
        _exporter = exporter;
      }}
      filterable={true}
    >
      {config.map((column) => {
        switch (column.tipo) {
          case "currency":
            const footerCurrency = column.total
              ? () => toCurrency(totales[column.key])
              : null;
            return (
              <ExcelExportColumn
                key={column.key}
                field={column.key}
                title={column.header}
                cellOptions={{ format: "$#,##0.00" }}
                footer={footerCurrency}
                footerCellOptions={{ textAlign: "right" }}
                width={column.width}
              />
            );
          case "percent":
            return (
              <ExcelExportColumn
                key={column.key}
                field={column.key}
                title={column.header}
                cellOptions={{ format: "0%" }}
                width={column.width}
              />
            );
          case "number":
            return (
              <ExcelExportColumn
                key={column.key}
                field={column.key}
                title={column.header}
                cellOptions={{ format: "0.0" }}
                width={column.width}
              />
            );
          case "fecha":
            return (
              <ExcelExportColumn
                key={column.key}
                field={column.key}
                title={column.header}
                cellOptions={{ format: "dd/mm/yyyy" }}
                locked={column.locked}
                width={column.width}
              />
            );

          default:
            const footer = column.total ? () => totales[column.key] : null;
            return (
              <ExcelExportColumn
                key={column.key}
                field={column.key}
                title={column.header}
                locked={column.locked}
                footer={footer}
                width={column.width}
              />
            );
        }
      })}
    </ExcelExport>
  );
};

export default XlsExport;
