import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientes } from "../../store/actions/clientes";
import { BuscarCliente } from "./BuscarCliente";
import { ListaClientes } from "./ListaClientes";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from "@material-ui/core/Grid";
import { NuevoCliente } from "./NuevoCliente";


export const Clientes = () => {
  // const [, dispatch] = useContext(ClientesContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.clientes.pageSize);
  const error = useSelector((state) => state.clientes.error);
  const showModal = useSelector((state) => state.clientes.showModal);

  useEffect(() => {
    dispatch(
      getClientes({
        pageSize,
        page: 1,
        buscando: true,
      })
    );
  }, [dispatch, pageSize]);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarCliente />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="clientes" />
        ) : (
          <ListaClientes />
        )}
      </Grid>
      {showModal && <NuevoCliente />}
    </Grid>
  );
};
