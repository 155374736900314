import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getObras } from "../../store/actions/obras";
import { BuscarObra } from "./BuscarObra";
import { ListaObras } from "./ListaObras";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from '@material-ui/core/Grid';
import { NuevaObra } from "./NuevaObra";

export const Obras = () => {
  // const [, dispatch] = useContext(ObrasContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.obras.pageSize);
  const error = useSelector((state) => state.obras.error);
  const showModal = useSelector((state) => state.obras.showModal);

  useEffect(() => {
    dispatch(
      getObras({
        pageSize,
        page: 1,
        buscando: true,
      })
    );
  }, [dispatch, pageSize]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarObra />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="obras" />
        ) : (
          <ListaObras />
        )}
      </Grid>
      {showModal && <NuevaObra />}
    </Grid>
  );
};
