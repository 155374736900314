export const maquinasTable = () => [
  { label: "Nombre", key: "nombre" },
  {
    label: "Tipo",
    key: "tipo",
    alias: { excavadora: "Excavadora", retro: "Retro" },
  },
  {
    label: "Empresa",
    key: "empresa",
    alias: { decmaq: "Maquinaria", decoin: "Decoin" },
  },
  { label: "Marca", key: "marca" },
  { label: "Modelo", key: "modelo" },
  { label: "Año", key: "year" },
  { label: "Horometro", key: "horometro" },
  { label: "Capacidad", key: "capacidad" },
  { label: "Serie", key: "serie" },
  { label: "Fecha Compra", key: "fechaCompra" },
];
