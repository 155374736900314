import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "maquinas/";

// axios.interceptors.request.use((config) => {
//     const token = Cookies.get('csrftoken');
//     console.log(token);
//     if (token) {
//         config.headers['X-CSRFToken'] = token;
//         console.log(token);
//     }
//     return config;
// }, (error) => {
//     Promise.reject(error);
// });

export const getMaquinas =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_MAQUINAS });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_MAQUINAS_SUCCESS,
        payload: {
          maquinas: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.MAQUINAS_NEXT_PAGE });
      dispatch(getMaquinas({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.MAQUINAS_PREV_PAGE });
      dispatch(getMaquinas({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.MAQUINAS_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getMaquinas({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.MAQUINAS_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.MAQUINAS_HIDE_MODAL });
  };
};

export const createMaquina = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_MAQUINA });
      const resp = await axios.post(url, data);
      console.log(resp);
      dispatch({ type: types.CREATE_MAQUINA_SUCCESS });
      dispatch(
        getMaquinas({
          page: 1,
          pageSize,
          buscando: true,
          dataBuscar: { estatus: "activo" },
        })
      );
    } catch (error) {
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getMaquina = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_MAQUINA });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_MAQUINA_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateMaquina = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_MAQUINA });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_MAQUINA_SUCCESS });
      dispatch(getMaquinas({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const toggleEstatus = ({ id, estatus, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TOGGLE_MAQUINA_ESTATUS });
      // const token = Cookies.get('csrftoken');
      await axios.patch(`${url}${id}/`, { estatus });
      dispatch({ type: types.TOGGLE_MAQUINA_ESTATUS_SUCCESS });
      dispatch(getMaquinas({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteMaquina = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_MAQUINA });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_MAQUINA_SUCCESS });
      dispatch(getMaquinas({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const maquinasSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.MAQUINAS_SET_EXPORTANDO, payload: exportar });
  };
};

export const getMaquinasExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_MAQUINAS_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_MAQUINAS_EXPORTAR_SUCCESS,
        payload: {
          maquinasExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.MAQUINAS_ERROR, payload: error.response });
    }
  };
