import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "traslados/";

export const getTraslados =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_TRASLADOS });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_TRASLADOS_SUCCESS,
        payload: {
          traslados: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TRASLADOS_NEXT_PAGE });
      dispatch(getTraslados({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TRASLADOS_PREV_PAGE });
      dispatch(getTraslados({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TRASLADOS_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getTraslados({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.TRASLADOS_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.TRASLADOS_HIDE_MODAL });
  };
};

export const createTraslado = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_TRASLADO });
      await axios.post(url, data);
      dispatch({ type: types.CREATE_TRASLADO_SUCCESS });
      dispatch(
        getTraslados({
          page: 1,
          pageSize,
          buscando: true,
        })
      );
      dispatch(getTrasladosEstado());
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getTraslado = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_TRASLADO });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_TRASLADO_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateTraslado = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_TRASLADO });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_TRASLADO_SUCCESS });
      dispatch(getTraslados({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const toggleEstatus = ({ id, activa, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TOOGLE_TRASLADO_ESTATUS });
      // const token = Cookies.get('csrftoken');
      await axios.patch(`${url}${id}/`, { activa });
      dispatch({ type: types.TOOGLE_TRASLADO_ESTATUS_SUCCESS });
      dispatch(getTraslados({ page, pageSize, dataBuscar }));
      dispatch(getTrasladosEstado());
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const toggleReparacion = ({
  id,
  en_reparacion,
  page,
  pageSize,
  dataBuscar,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TOGGLE_TRASLADO_REPARACION });
      // const token = Cookies.get('csrftoken');
      await axios.patch(`${url}${id}/`, { en_reparacion });
      dispatch({ type: types.TOGGLE_TRASLADO_REPARACION_SUCCESS });
      dispatch(getTraslados({ page, pageSize, dataBuscar }));
      dispatch(getTrasladosEstado());
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteTraslado = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_TRASLADO });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_TRASLADO_SUCCESS });
      dispatch(getTraslados({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const getTrasladosEstado = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_TRASLADOS_ESTADO });
      const response = await axios.get(`${url}estado/`, {
        params: { page: 1, pageSize: 100000 },
      });
      dispatch({
        type: types.GET_TRASLADOS_ESTADO_SUCCESS,
        payload: response.data.results,
      });
    } catch (error) {
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const trasladosSetPretraslado = (traslado) => {
  return (dispatch) => {
    dispatch({ type: types.TRASLADOS_SET_PRETRASLADO, payload: traslado });
  };
};

export const trasladosSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.TRASLADOS_SET_EXPORTANDO, payload: exportar });
  };
};

export const getTrasladosExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_TRASLADOS_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_TRASLADOS_EXPORTAR_SUCCESS,
        payload: {
          trasladosExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.TRASLADOS_ERROR, payload: error.response });
    }
  };
