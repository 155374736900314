import produce from "immer";
import * as types from "../types";

const initialState = {
  accesorios: [],
  accesoriosExportar: [],
  accesorio: {},
  isLoading: false,
  error: null,
  page: 1,
  pageSize: 25,
  pageMax: false,
  dataBuscar: {},
  showModal: false,
  editMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCESORIOS:
    case types.GET_ACCESORIOS_EXPORTAR:
    case types.GET_ACCESORIO:
    case types.CREATE_ACCESORIO:
    case types.UPDATE_ACCESORIO:
    case types.DELETE_ACCESORIO:
    case types.TOGGLE_ACCESORIO_ESTATUS:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case types.GET_ACCESORIOS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.accesorios = action.payload.accesorios;
        draft.pageMax = action.payload.next === null;
        draft.error = null;
        draft.dataBuscar = action.payload.dataBuscar
          ? action.payload.dataBuscar
          : {};
        if (action.payload.buscando) draft.page = 1;
      });
    case types.ACCESORIOS_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page + 1;
      });
    case types.ACCESORIOS_PREV_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page - 1;
      });
    case types.ACCESORIOS_CHANGE_PAGE_SIZE:
      return produce(state, (draft) => {
        draft.page = 1;
        draft.pageSize = action.payload;
      });
    case types.ACCESORIOS_SHOW_MODAL:
      return produce(state, (draft) => {
        draft.showModal = true;
      });
    case types.ACCESORIOS_HIDE_MODAL:
      return produce(state, (draft) => {
        draft.showModal = false;
        draft.editMode = false;
        draft.accesorio = {};
        draft.error = null;
      });
    case types.CREATE_ACCESORIO_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.showModal = false;
      });
    case types.GET_ACCESORIO_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = true;
        draft.showModal = true;
        draft.accesorio = action.payload;
      });
    case types.UPDATE_ACCESORIO_SUCCESS:
    case types.DELETE_ACCESORIO_SUCCESS:
    case types.TOGGLE_ACCESORIO_ESTATUS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = false;
        draft.accesorio = {};
        draft.showModal = false;
      });
    case types.ACCESORIOS_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = action.payload ? action.payload : "offline";
      });
    case types.ACCESORIOS_SET_EXPORTANDO:
      return produce(state, (draft) => {
        draft.exportando = action.payload;
      });
    case types.GET_ACCESORIOS_EXPORTAR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        // draft.showModal = false;
        draft.accesoriosExportar = action.payload.accesoriosExportar;
        draft.error = null;
        draft.exportando = true;
      });
    default:
      return state;
  }
};
