import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  createAccesorio,
  updateAccesorio,
  deleteAccesorio,
  toggleEstatus,
} from "../../store/actions/accesorios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { ErrorMessage } from "../../components/ErrorMessage";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../lib/styles/nuevoStyles";

import {
  accesoriosForm,
  defaultVal,
  validation,
} from "../../lib/forms/accesoriosForm";
import { fechaHoyIso } from "../../shared";

export const NuevoAccesorio = () => {
  const showModal = useSelector((state) => state.accesorios.showModal);
  const page = useSelector((state) => state.accesorios.page);
  const pageSize = useSelector((state) => state.accesorios.pageSize);
  const dataBuscar = useSelector((state) => state.accesorios.dataBuscar);
  const accesorio = useSelector((state) => state.accesorios.accesorio);
  const editMode = useSelector((state) => state.accesorios.editMode);
  const isLoading = useSelector((state) => state.accesorios.isLoading);
  const error = useSelector((state) => state.accesorios.error);
  const dispatch = useDispatch();

  const classes = useStyles();

  const formModel = accesoriosForm();
  const defaultValues = defaultVal();
  const validationSchema = validation();

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: editMode
      ? accesorio
      : { ...defaultValues, fechaCompra: fechaHoyIso() },
  });

  const onDelete = () => {
    const id = accesorio.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar el accesorio ${accesorio.nombre}?`
      )
    ) {
      dispatch(deleteAccesorio({ id, page, pageSize, dataBuscar }));
    }
  };

  const onToggleEstatus = () => {
    const estatus = accesorio.estatus === "activo" ? "inactivo" : "activo";
    const id = accesorio.id;
    dispatch(toggleEstatus({ id, estatus, page, pageSize, dataBuscar }));
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      estatus: "activo",
    };
    const id = accesorio.id;
    if (editMode) {
      dispatch(updateAccesorio({ id, data, page, pageSize, dataBuscar }));
    } else {
      dispatch(createAccesorio({ data, pageSize }));
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          dispatch(hideModal());
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editMode ? "Editar Accesorio" : "Agregar Nuevo Accesorio"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid container spacing={2}>
              {formModel.map((input) => (
                <Grid item key={input.props.name} md={input.width} xs={12}>
                  <InputField
                    {...input.props}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          {error && <ErrorMessage module="accesorios" />}
        </DialogContent>
        <DialogActions>
          {editMode && (
            <Button onClick={onDelete} className={classes.warning}>
              Borrar Accesorio
            </Button>
          )}
          {editMode && (
            <Button
              color="primary"
              onClick={onToggleEstatus}
              disabled={isLoading}
            >
              {accesorio.estatus === "activo"
                ? "Desactivar Accesorio"
                : "Activar Accesorio"}
            </Button>
          )}
          <Button
            onClick={() => dispatch(hideModal())}
            color="primary"
            tabIndex={-1}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Editar" : "Capturar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
