import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import React from "react";
import { capitalize } from "../shared";

const formatAsCurrency = (n, currency) => {
  return currency + " " + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};

export const toCurrency = (monto) => {
  var valor = formatAsCurrency(monto, "$");
  return valor.toString();
};

export const toNumber = (m) => {
  m = m.replace(/ /g, "");
  m = m.replace(/,/g, "");
  m = m.replace("$", "");
  m = m.replace("%", "");
  m = parseFloat(m);
  return m;
};

export const patterns = {
  imss: /^(\d{11})?$/,
  cuenta: /^(\d{10,})?$/,
  cp: /^(\d{5})?$/,
  telephone: /^(\d{2}-\d{4}-\d{4})?$/,
  rfc: /^([a-z]{3}([a-z]{1})?\d{6}[a-z0-9]{3})?$/i,
  curp: /^([a-z]{4}\d{6}[a-z0-9]{8})?$/i,
  number: /^(\d+\.?(\d{1,4})?)?$/,
  letters: /^([a-zA-Z\u00f1\u00d1\s]+)?$/,
  text: /^([a-zA-Z0-9\s]+)?$/,
  ot: /^(OT\d{4})?$/,
  tipoPrestamo: /^((Prestamo)|(Abono))?$/,
  numero: /^(\d*)$/,
  fecha: /^(\d{2}\/\d{2}\/\d{4})?$/i,
};

export const removeMask = (value) => {
  return value.replace(/-|_|\(|\)|\s/g, "");
};

const masks = {
  cp: "99999",
  telefono: "99-9999-9999",
  celular: "99-9999-9999",
  numot: "OT9999",
};

export default function InputField({
  errors,
  label,
  name,
  inputProps,
  options,
  uppercase,
  capitalcase,
  currency,
  autoFocus,
  onChange,
  type,
  select,
  control,
  mask,
  autoComplete,
  acOptions,
  acLabel,
  disabled,
  inputRef,
}) {
  const format = uppercase
    ? "uppercase"
    : currency
    ? "currency"
    : capitalcase
    ? "capitalcase"
    : null;
  // format = currency ? "currency" : null;
  // const inputRef = useRef();

  // useEffect(() => {
  //   if (focus) inputRef.current.focus();
  // }, []);

  const handleBlur = (e, onChange) => {
    switch (format) {
      case "uppercase":
        onChange(e.target.value.toUpperCase());
        break;
      case "capitalcase":
        onChange(capitalize(e.target.value));
        break;
      case "currency":
        onChange(toCurrency(toNumber(e.target.value)));
        break;
      default:
        break;
    }
  };

  const handleChange = (e, change) => {
    change(e);
    if (onChange) onChange(e);
    // if (mask) {
    //   change(e.target.value.replace(patterns[mask], "$1$2$3"));
    // }
  };

  const textField = (props) => (
    <TextField
      InputProps={{
        ...inputProps,
      }}
      error={errors[name] ? true : false}
      label={label}
      type={type}
      disabled={disabled}
      inputRef={inputRef}
      value={props.value}
      onChange={(e) => {
        handleChange(e, props.onChange);
      }}
      helperText={errors[name]?.message}
      autoFocus={autoFocus}
      onBlur={(e) => {
        props.onBlur(handleBlur(e, props.onChange));
      }}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{ shrink: true }}
      select={select}
      SelectProps={{ native: true }}
    >
      {options?.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );

  const autoCompleteField = (props) => (
    <Autocomplete
      {...props}
      autoHighlight
      autoSelect
      disabledItemsFocusable
      disabled={disabled}
      options={acOptions}
      onBlur={props.onBlur}
      // onChange={(_, newValue) => props.onChange(newValue)}
      onChange={(e, newValue) => {
        props.onChange(newValue || "");
        if (onChange) onChange(e, newValue);
      }}
      getOptionLabel={(option) => option[acLabel] || ""}
      getOptionSelected={(option, value) =>
        value === "" || option.id === value.id
      }
      renderInput={(params) => (
        <TextField
          inputProps={{
            ...inputProps,
            // ...params.inputProps,
          }}
          {...params}
          inputRef={inputRef}
          error={errors[name] ? true : false}
          label={label}
          type={type}
          InputLabelProps={{ shrink: true }}
          helperText={errors[name]?.message}
          autoFocus={autoFocus}
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
    />
  );

  const maskedTextField = (props) => (
    <InputMask
      value={props.value}
      disabled={false}
      onChange={(e) => handleChange(e, props.onChange)}
      onBlur={(e) => {
        props.onBlur(handleBlur(e, props.onChange));
      }}
      mask={masks[name]}
    >
      {/* {() => ( */}
      <TextField
        inputProps={{
          ...inputProps,
        }}
        error={errors[name] ? true : false}
        label={label}
        type={type}
        helperText={errors[name]?.message}
        autoFocus={autoFocus}
        variant="outlined"
        size="small"
        fullWidth
        select={select}
      >
        {options?.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {/* )} */}
    </InputMask>
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={autoComplete ? null : undefined}
      // render={(props) => (mask ? maskedTextField(props) : textField(props))}
      // render={(props) => textField(props)}
      render={(props) => {
        if (autoComplete) return autoCompleteField(props);
        if (mask) return maskedTextField(props);
        return textField(props);
      }}
    />
  );
}
