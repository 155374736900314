import * as yup from "yup";
// import { patterns } from "../../components/InputField";

export const defaultVal = () => ({
  nombre: "",
  apellido: "",
  tipo: "",
  estatus: "activo",
});

export const validation = () =>
  yup.object({
    nombre: yup.string().required("Requerido"),
    apellido: yup.string().required("Requerido"),
    tipo: yup.string().required("Requerido"),
  });

export const operadoresForm = () => [
  {
    props: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      uppercase: true,
      autoFocus: true,
    },
    width: 5,
  },
  {
    props: {
      name: "apellido",
      label: "Apellido",
      type: "text",
      uppercase: true,
    },
    width: 7,
  },
  {
    props: {
      name: "tipo",
      label: "Tipo",
      select: true,
      options: [
        { label: "", value: "" },
        { label: "Excavadora", value: "excavadora" },
        { label: "Retro", value: "retro" },
      ],
    },
    width: 4,
  },
  {
    props: {
      name: "estatus",
      label: "Estatus",
      type: "text",
      disabled: true,
    },
    width: 2,
  },
];
