import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { deleteTraslado } from "../../store/actions/traslados";

import { Lista } from "../../components/Lista";
import { trasladosTable } from "../../lib/tables/trasladosTable";

export const ListaTraslados = () => {
  const traslados = useSelector((state) => state.traslados.traslados);
  const page = useSelector((state) => state.traslados.page);
  const pageSize = useSelector((state) => state.traslados.pageSize);
  const dataBuscar = useSelector((state) => state.traslados.dataBuscar);

  const dispatch = useDispatch();

  const listaModel = trasladosTable();

  const onDelete = (traslado) => {
    const id = traslado.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar el traslado de la maquina ${traslado.maquina.nombre}, de ${traslado.obraOrigen?.nombre} a ${traslado.obraDestino?.nombre}?`
      )
    ) {
      dispatch(deleteTraslado({ id, page, pageSize, dataBuscar }));
    }
  };

  return (
    <Lista
      module="traslados"
      onDelete={onDelete}
      docs={traslados}
      listaModel={listaModel}
    />
  );

  // return (
  //   <>
  //     {/* <Pagination module="traslados" /> */}
  //     <TableContainer component={Paper}>
  //       <Table size="small" className={classes.table}>
  //         <TableHead>
  //           <TableRow>
  //             <PaginationM module="traslados" />
  //           </TableRow>
  //           <TableRow>
  //             <TableCell>Fecha</TableCell>
  //             <TableCell>Tipo</TableCell>
  //             <TableCell>Maquina</TableCell>
  //             <TableCell>Cliente</TableCell>
  //             <TableCell>Origen</TableCell>
  //             <TableCell>Destino</TableCell>
  //             <TableCell>Operador</TableCell>
  //             <TableCell>Accesorio</TableCell>
  //             <TableCell>Proveedor</TableCell>
  //             <TableCell>Precio Venta</TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small">
  //                 <DeleteIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {traslados.map((doc) => (
  //             <StyledTableRow key={doc.id} hover className={classes.hover}>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.fecha}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.tipo === "movimiento" ? "Movimiento" : "Cambio Operador"}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.maquina?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.cliente?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.obraOrigen?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.obraDestino?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.operador?.busqueda}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.accesorio?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.proveedor?.nombre}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 {doc.precioVenta}
  //               </TableCell>
  //               <TableCell className={classes.tableCell}>
  //                 <IconButton size="small" onClick={() => onDelete(doc)}>
  //                   <DeleteIcon style={{ fontSize: 16 }} />
  //                 </IconButton>
  //               </TableCell>
  //             </StyledTableRow>
  //           ))}
  //         </TableBody>
  //         <TableFooter></TableFooter>
  //       </Table>
  //     </TableContainer>
  //   </>
  // );
};
