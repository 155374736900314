import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  createOperador,
  updateOperador,
  deleteOperador,
  toggleEstatus,
} from "../../store/actions/operadores";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { ErrorMessage } from "../../components/ErrorMessage";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStyles } from '../../lib/styles/nuevoStyles';

import {
  operadoresForm,
  defaultVal,
  validation,
} from "../../lib/forms/operadoresForm";

export const NuevoOperador = () => {
  const showModal = useSelector((state) => state.operadores.showModal);
  const page = useSelector((state) => state.operadores.page);
  const pageSize = useSelector((state) => state.operadores.pageSize);
  const dataBuscar = useSelector((state) => state.operadores.dataBuscar);
  const operador = useSelector((state) => state.operadores.operador);
  const editMode = useSelector((state) => state.operadores.editMode);
  const isLoading = useSelector((state) => state.operadores.isLoading);
  const error = useSelector((state) => state.operadores.error);
  const dispatch = useDispatch();

  const classes = useStyles();

  const formModel = operadoresForm();
  const defaultValues = defaultVal();
  const validationSchema = validation();

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: editMode ? operador : defaultValues,
  });

  const onDelete = () => {
    const id = operador.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar el operador ${operador.busqueda}?`
      )
    ) {
      dispatch(deleteOperador({ id, page, pageSize, dataBuscar }));
    }
  };

  const onToggleEstatus = () => {
    const estatus = operador.estatus === "activo" ? "inactivo" : "activo";
    const id = operador.id;
    dispatch(toggleEstatus({ id, estatus, page, pageSize, dataBuscar }));
  };

  const onSubmit = (data) => {
    data = {
      ...data,
    };
    const id = operador.id;
    if (editMode) {
      dispatch(updateOperador({ id, data, page, pageSize, dataBuscar }));
    } else {
      dispatch(createOperador({ data, pageSize }));
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          dispatch(hideModal());
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editMode ? "Editar Operador" : "Agregar Nuevo Operador"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid container spacing={2}>
              {formModel.map((input) => (
                <Grid item key={input.props.name} md={input.width} xs={12}>
                  <InputField
                    {...input.props}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          {error && <ErrorMessage module="operadores" />}
        </DialogContent>
        <DialogActions>
          {editMode && (
            <Button onClick={onDelete} className={classes.warning}>
              Borrar Operador
            </Button>
          )}
          {editMode && (
            <Button
              color="secondary"
              onClick={onToggleEstatus}
              disabled={isLoading}
            >
              {operador.estatus === "activo"
                ? "Desactivar Operador"
                : "Activar Operador"}
            </Button>
          )}
          <Button
            onClick={() => dispatch(hideModal())}
            color="primary"
            tabIndex={-1}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Editar" : "Capturar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
