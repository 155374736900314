import produce from 'immer';
import * as types from '../types';

const initialState = {
    reportesDiesel: [],
    reportesDieselExportar: [],
    reporteDiesel: {},
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageMax: false,
    dataBuscar: {},
    showModal: false,
    exportando: false,
    editMode: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REPORTES_DIESEL:
        case types.GET_REPORTES_DIESEL_EXPORTAR:
        case types.GET_REPORTE_DIESEL:
        case types.CREATE_REPORTE_DIESEL:
        case types.UPDATE_REPORTE_DIESEL:
        case types.DELETE_REPORTE_DIESEL:
            return produce(state, (draft) => {
                draft.isLoading = true;
            });
        case types.GET_REPORTES_DIESEL_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                // draft.showModal = false;
                draft.reportesDiesel = action.payload.reportesDiesel;
                draft.pageMax = action.payload.next === null;
                draft.error = null;
                draft.dataBuscar = action.payload.dataBuscar ? action.payload.dataBuscar : {};
                if (action.payload.buscando) draft.page = 1;
            });
        case types.DIESEL_NEXT_PAGE:
            return produce(state, (draft) => {
                draft.page = draft.page + 1;
            });
        case types.DIESEL_PREV_PAGE:
            return produce(state, (draft) => {
                draft.page = draft.page - 1;
            });
        case types.DIESEL_CHANGE_PAGE_SIZE:
            return produce(state, (draft) => {
                draft.page = 1;
                draft.pageSize = action.payload;
            });
        case types.DIESEL_SHOW_MODAL:
            return produce(state, (draft) => {
                draft.showModal = true;
            });
        case types.DIESEL_HIDE_MODAL:
            return produce(state, (draft) => {
                draft.showModal = false;
                draft.editMode = false;
                draft.reporteDiesel = {};
                draft.error = null;
            });
        case types.CREATE_REPORTE_DIESEL_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = null;
            });
        case types.GET_REPORTE_DIESEL_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = null;
                draft.editMode = true;
                draft.showModal = true;
                draft.reporteDiesel = action.payload;
            });
        case types.UPDATE_REPORTE_DIESEL_SUCCESS:
        case types.DELETE_REPORTE_DIESEL_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = null;
                draft.editMode = false;
                draft.reporteDiesel = {};
                draft.showModal = false;
            });
        case types.REPORTES_DIESEL_ERROR:
            return produce(state, (draft) => {
                draft.isLoading = false;
                draft.error = action.payload ? action.payload : 'offline';
            });
        case types.DIESEL_SET_EXPORTANDO:
            return produce(state, (draft) => {
                draft.exportando = action.payload;
            });
        case types.GET_REPORTES_DIESEL_EXPORTAR_SUCCESS:
            return produce(state, (draft) => {
                draft.isLoading = false;
                // draft.showModal = false;
                draft.reportesDieselExportar = action.payload.reportesDieselExportar;
                draft.error = null;
                draft.exportando = true;
            });
        default:
            return state;
    }
}

