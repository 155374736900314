import * as yup from "yup";
// import { patterns } from "../../components/InputField";

// export const defaultVal = () => ({
//   horometro: 0,
//   capacidadDiesel: 0,
//   tipo: "",
//   empresa: "decmaq",
//   estatus: "activo",
// });

export const validation = () =>
  yup.object({
    maquina: yup.string().required("Requerido"),
    fecha: yup.string().required("Requerido"),
    litros: yup
      .number()
      .required("Requerido")
      .min(0, "Monto invalido")
      .typeError("Monto invalido"),
    horometro: yup
      .number()
      .required("Requerido")
      .min(0, "Horometro invalido")
      .typeError("Horometro invalido"),
    comentarios: yup.string(),
  });

export const fechasForm = () => [
  {
    props: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      uppercase: true,
      autoFocus: true,
    },
    width: 4,
  },
  {
    props: {
      name: "tipo",
      label: "Tipo",
      select: true,
      options: [
        { label: "", value: "" },
        { label: "Excavadora", value: "excavadora" },
        { label: "Retro", value: "retro" },
      ],
    },
    width: 4,
  },
  {
    props: {
      name: "fechaCompra",
      label: "Fecha Compra",
      type: "date",
    },
    width: 4,
  }, //nueva fila
  {
    props: {
      name: "empresa",
      label: "Empresa",
      select: true,
      options: [
        { label: "", value: "" },
        { label: "Decoin Maquinaria", value: "decmaq" },
        { label: "Decoin", value: "decoin" },
      ],
    },
    width: 4,
  },
  {
    props: {
      name: "marca",
      label: "Marca",
      type: "text",
      capitalcase: true,
    },
    width: 3,
  },
  {
    props: {
      name: "modelo",
      label: "Modelo",
      type: "text",
      uppercase: true,
    },
    width: 3,
  },
  {
    props: {
      name: "year",
      label: "Año",
      type: "text",
    },
    width: 2,
  },
  {
    props: {
      name: "serie",
      label: "Serie",
      type: "text",
      uppercase: true,
    },
    width: 5,
  },
  {
    props: {
      name: "capacidadDiesel",
      label: "Capacidad Diesel",
      type: "text",
    },
    width: 3,
  },
  {
    props: {
      name: "horometro",
      label: "Horometro",
      type: "text",
    },
    width: 2,
  },
  {
    props: {
      name: "estatus",
      label: "Estatus",
      type: "text",
      disabled: true,
    },
    width: 2,
  },
];
