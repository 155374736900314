import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Lista } from "../../components/Lista";
import { proveedoresTable } from "../../lib/tables/proveedoresTable";
import { getProveedor } from "../../store/actions/proveedores";

export const ListaProveedores = () => {
  const proveedores = useSelector((state) => state.proveedores.proveedores);

  const dispatch = useDispatch();

  const onUpdate = (id) => {
    dispatch(getProveedor(id));
  };

  return (
    <Lista
      module="proveedores"
      docs={proveedores}
      onUpdate={onUpdate}
      listaModel={proveedoresTable()}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="proveedores" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Nombre</TableCell>
  //           <TableCell>RFC</TableCell>
  //           <TableCell>Notas</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {proveedores.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>
  //               {doc.nombre}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.rfc}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.notas}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
