import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Lista } from "../../components/Lista";
import { obrasTable } from "../../lib/tables/obrasTable";
import { getObra } from "../../store/actions/obras";

export const ListaObras = () => {
  const obras = useSelector((state) => state.obras.obras);

  const dispatch = useDispatch();

  const onUpdate = (id) => {
    dispatch(getObra(id));
  };

  return (
    <Lista
      module="obras"
      docs={obras}
      onUpdate={onUpdate}
      listaModel={obrasTable()}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="obras" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Nombre</TableCell>
  //           <TableCell>Notas</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {obras.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>
  //               {doc.nombre}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.notas}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
