import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getReportesDiesel,
  showModal,
  getReportesDieselExportar,
} from "../../store/actions/diesel";
import { XlsExport } from "../../components/XlsExport";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputField from "../../components/InputField";

export const BuscarDiesel = () => {
  const pageSize = useSelector((state) => state.diesel.pageSize);
  const isLoading = useSelector((state) => state.diesel.isLoading);
  const exportando = useSelector((state) => state.diesel.exportando);

  const dispatch = useDispatch();

  const hoy = new Date();
  const year = hoy.getFullYear();

  const initialValues = {
    search: "",
    fecha_after: `${year - 1}-01-01`,
    fecha_before: `${year}-12-31`,
  };

  const { control, handleSubmit, reset, getValues, errors } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = (data) => {
    dispatch(
      getReportesDiesel({ page: 1, pageSize, dataBuscar: data, buscando: true })
    );
  };

  const onCleanSearch = (e) => {
    dispatch(getReportesDiesel({ page: 1, pageSize, buscando: true }));
    reset(initialValues);
  };

  const onExportar = () => {
    const dataBuscar = getValues();
    dispatch(getReportesDieselExportar({ dataBuscar }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <InputField
              label="Fecha Inicial"
              name="fecha_after"
              type="date"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <InputField
              label="Fecha Final"
              name="fecha_before"
              type="date"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              label="Maquina"
              name="search"
              type="text"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => onCleanSearch(e)}
            >
              Limpiar Busqueda
            </Button>{" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(showModal())}
            >
              Nuevo Reporte
            </Button>{" "}
            <Button variant="outlined" color="primary" onClick={onExportar}>
              Exportar
            </Button>
          </Grid>
        </Grid>
      </form>
      {exportando && <XlsExport title="Diesel" />}
    </>
  );
};
