import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "diesel/";

// axios.interceptors.request.use((config) => {
//     const token = Cookies.get('csrftoken');
//     console.log(token);
//     if (token) {
//         config.headers['X-CSRFToken'] = token;
//         console.log(token);
//     }
//     return config;
// }, (error) => {
//     Promise.reject(error);
// });

export const getReportesDiesel =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_REPORTES_DIESEL });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_REPORTES_DIESEL_SUCCESS,
        payload: {
          reportesDiesel: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DIESEL_NEXT_PAGE });
      dispatch(getReportesDiesel({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DIESEL_PREV_PAGE });
      dispatch(getReportesDiesel({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DIESEL_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getReportesDiesel({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.DIESEL_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.DIESEL_HIDE_MODAL });
  };
};

export const createReporteDiesel = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_REPORTE_DIESEL });
      // const prevHorometro = (
      //   await axios.get(baseUrl + "prevHorometro", {
      //     params: {
      //       id: data.maquina,
      //       fecha: data.fecha,
      //     },
      //   })
      // ).data;

      // const maquina = (await axios.get(baseUrl + "maquinas/" + data.maquina))
      //   .data;

      // if (data.horometro !== 0 && data.horometro < prevHorometro) {
      //   throw new Error("horometro invalido");
      // }

      // let diferencia = data.horometro - prevHorometro;
      // if (data.horometro !== 0 && prevHorometro > 1 && diferencia > 50) {
      //   throw new Error("horometro invalido diferencia");
      // }

      // if (data.litros > maquina.capacidadDiesel) {
      //   throw new Error("litros invalido");
      // }

      // diferencia = diferencia < 0 ? 0 : diferencia;
      // const updatedData = {
      //     ...data,
      //     horasReales: diferencia
      // }

      await axios.post(url, data);
      // if (data.horometro !== 0 && data.horometro > maquina.horometro) {
      //   await axios.patch(baseUrl + "maquinas/" + data.maquina + "/", {
      //     horometro: data.horometro,
      //   });
      // }
      dispatch({ type: types.CREATE_REPORTE_DIESEL_SUCCESS });
      dispatch(getReportesDiesel({ page: 1, pageSize, buscando: true }));
    } catch (error) {
      if (error.response) {
        dispatch({
          type: types.REPORTES_DIESEL_ERROR,
          payload: error.response,
        });
        console.log(error.response);
      } else {
        dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.message });
        console.log(error.message);
      }
    }
  };
};

export const getReporteDiesel = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_REPORTE_DIESEL });
      const response = await axios.get(`${url}${id}/`);
      dispatch({
        type: types.GET_REPORTE_DIESEL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateReporteDiesel = ({
  id,
  data,
  page,
  pageSize,
  dataBuscar,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_REPORTE_DIESEL });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_REPORTE_DIESEL_SUCCESS });
      dispatch(getReportesDiesel({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteReporteDiesel = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_REPORTE_DIESEL });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_REPORTE_DIESEL_SUCCESS });
      dispatch(getReportesDiesel({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const dieselSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.DIESEL_SET_EXPORTANDO, payload: exportar });
  };
};

export const getReportesDieselExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_REPORTES_DIESEL_EXPORTAR });
    try {
      const response = await axios.get(url + "exportar/", {
        params: {
          page: 1,
          pageSize: 2000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_REPORTES_DIESEL_EXPORTAR_SUCCESS,
        payload: {
          reportesDieselExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.REPORTES_DIESEL_ERROR, payload: error.response });
    }
  };
