import * as yup from "yup";
// import { patterns } from "../../components/InputField";

export const defaultVal = () => ({
  nombre: "",
  marca: "",
  modelo: "",
  serie: "",
  year: "",
  empresa: "decmaq",
  estatus: "activo",
});

export const validation = () =>
  yup.object({
    nombre: yup
      .string()
      .required("Requerido")
      .max(10, "El nombre no puede tener mas de 10 caracteres"),
    fechaCompra: yup.string().required("Requerido"),
    empresa: yup.string().required("Requerido"),
    marca: yup.string().required("Requerido"),
    modelo: yup.string().required("Requerido"),
    year: yup
      .number()
      .typeError("Año invalido")
      .required("Requerido")
      .positive("Año invalido")
      .min(1800, "Año invalido")
      .max(9999, "Año invalido"),
    serie: yup.string().required("Requerido"),
  });

export const accesoriosForm = () => [
  {
    props: {
      name: "nombre",
      label: "Nombre",
      type: "text",
      uppercase: true,
      autoFocus: true,
    },
    width: 4,
  },
  {
    props: {
      name: "fechaCompra",
      label: "Fecha Compra",
      type: "date",
    },
    width: 4,
  },
  {
    props: {
      name: "empresa",
      label: "Empresa",
      select: true,
      options: [
        { label: "", value: "" },
        { label: "Decoin Maquinaria", value: "decmaq" },
        { label: "Decoin", value: "decoin" },
      ],
    },
    width: 4,
  },
  {
    props: {
      name: "marca",
      label: "Marca",
      type: "text",
      capitalcase: true,
    },
    width: 5,
  },
  {
    props: {
      name: "modelo",
      label: "Modelo",
      type: "text",
      uppercase: true,
    },
    width: 5,
  },
  {
    props: {
      name: "year",
      label: "Año",
      type: "text",
    },
    width: 2,
  },
  {
    props: {
      name: "serie",
      label: "Serie",
      type: "text",
      uppercase: true,
    },
    width: 10,
  },
  {
    props: {
      name: "estatus",
      label: "Estatus",
      type: "text",
      disabled: true,
    },
    width: 2,
  },
];
