import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTraslados } from "../../store/actions/traslados";
import { getMaquinas } from "../../store/actions/maquinas";
import { getClientes } from "../../store/actions/clientes";
import { getOperadores } from "../../store/actions/operadores";
import { getProveedores } from "../../store/actions/proveedores";
import { getObras } from "../../store/actions/obras";
import { getAccesorios } from "../../store/actions/accesorios";
import { BuscarTraslado } from "./BuscarTraslado";
import { ListaTraslados } from "./ListaTraslados";
import { ErrorMessage } from "../../components/ErrorMessage";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { EstadoTraslados } from "./EstadoTraslados";
import { NuevoTraslado } from "./NuevoTraslado";
export const Traslados = () => {
  // const [, dispatch] = useContext(TrasladosContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.traslados.pageSize);
  const error = useSelector((state) => state.traslados.error);
  const showModal = useSelector((state) => state.traslados.showModal);

  const [tabValue, setTabValue] = React.useState("estado");

  useEffect(() => {
    dispatch(
      getTraslados({
        pageSize,
        page: 1,
        buscando: true,
      })
    );
    // dispatch(getTrasladosEstado());
    dispatch(
      getMaquinas({
        page: 1,
        pageSize: 100000,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
    dispatch(
      getOperadores({
        page: 1,
        pageSize: 100000,
        buscando: true,
        dataBuscar: { estatus: "activo" },
      })
    );
    dispatch(getClientes({ page: 1, pageSize: 100000, buscando: true }));
    dispatch(getProveedores({ page: 1, pageSize: 100000, buscando: true }));
    dispatch(getObras({ page: 1, pageSize: 100000, buscando: true }));
    dispatch(getAccesorios({ page: 1, pageSize: 100000, buscando: true }));
  }, [dispatch, pageSize]);

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          // variant="fullWidth"
          indicatorColor="primary"
          textColor="secondary"
        >
          <Tab label="ESTADO DE MAQUINARIA" value="estado" />
          <Tab label="REGISTRO DE TRASLADOS" value="registro" />
        </Tabs>
      </Grid>
      {tabValue === "estado" && (
        <>
          {error && !showModal ? (
            <ErrorMessage module="traslados" />
          ) : (
            <EstadoTraslados />
          )}
        </>
      )}
      {tabValue === "registro" && (
        <>
          <Grid item xs={12}>
            <BuscarTraslado />
          </Grid>
          <Grid item xs={12}></Grid>
          {error && !showModal ? (
            <ErrorMessage module="traslados" />
          ) : (
            <ListaTraslados />
          )}
        </>
      )}
      {showModal && <NuevoTraslado />}
    </Grid>
  );
};
