import produce from "immer";
import * as types from "../types";

const initialState = {
  obras: [],
  obrasExportar: [],
  obra: {},
  isLoading: false,
  error: null,
  page: 1,
  pageSize: 25,
  pageMax: false,
  dataBuscar: {},
  showModal: false,
  editMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OBRAS:
    case types.GET_OBRAS_EXPORTAR:
    case types.GET_OBRA:
    case types.CREATE_OBRA:
    case types.UPDATE_OBRA:
    case types.DELETE_OBRA:
      return produce(state, (draft) => {
        draft.isLoading = true;
      });
    case types.GET_OBRAS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.obras = action.payload.obras;
        draft.pageMax = action.payload.next === null;
        draft.error = null;
        draft.dataBuscar = action.payload.dataBuscar
          ? action.payload.dataBuscar
          : {};
        if (action.payload.buscando) draft.page = 1;
      });
    case types.OBRAS_NEXT_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page + 1;
      });
    case types.OBRAS_PREV_PAGE:
      return produce(state, (draft) => {
        draft.page = draft.page - 1;
      });
    case types.OBRAS_CHANGE_PAGE_SIZE:
      return produce(state, (draft) => {
        draft.page = 1;
        draft.pageSize = action.payload;
      });
    case types.OBRAS_SHOW_MODAL:
      return produce(state, (draft) => {
        draft.showModal = true;
      });
    case types.OBRAS_HIDE_MODAL:
      return produce(state, (draft) => {
        draft.showModal = false;
        draft.editMode = false;
        draft.obra = {};
        draft.error = null;
      });
    case types.CREATE_OBRA_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.showModal = false;
      });
    case types.GET_OBRA_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = true;
        draft.showModal = true;
        draft.obra = action.payload;
      });
    case types.UPDATE_OBRA_SUCCESS:
    case types.DELETE_OBRA_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = null;
        draft.editMode = false;
        draft.obra = {};
        draft.showModal = false;
      });
    case types.OBRAS_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.error = action.payload ? action.payload : "offline";
      });
    case types.OBRAS_SET_EXPORTANDO:
      return produce(state, (draft) => {
        draft.exportando = action.payload;
      });
    case types.GET_OBRAS_EXPORTAR_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        // draft.showModal = false;
        draft.obrasExportar = action.payload.obrasExportar;
        draft.error = null;
        draft.exportando = true;
      });
    default:
      return state;
  }
};
