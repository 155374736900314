import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Lista } from "../../components/Lista";
import { operadoresTable } from "../../lib/tables/operadoresTable";
import { getOperador } from "../../store/actions/operadores";

export const ListaOperadores = () => {
  const operadores = useSelector((state) => state.operadores.operadores);

  const dispatch = useDispatch();

  const onUpdate = (id) => {
    dispatch(getOperador(id));
  };

  return (
    <Lista
      module="operadores"
      docs={operadores}
      onUpdate={onUpdate}
      listaModel={operadoresTable()}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="operadores" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Nombre</TableCell>
  //           <TableCell>Tipo</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {operadores.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>
  //               {doc.busqueda}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.tipo === "excavadora" ? "Excavadora" : "Retro"}
  //             </TableCell>

  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
