export const GET_MAQUINAS = "GET_MAQUINAS";
export const GET_MAQUINAS_SUCCESS = "GET_MAQUINAS_SUCCESS";
export const GET_MAQUINA = "GET_MAQUINA";
export const GET_MAQUINA_SUCCESS = "GET_MAQUINA_SUCCESS";
export const CREATE_MAQUINA = "CREATE_MAQUINA";
export const CREATE_MAQUINA_SUCCESS = "CREATE_MAQUINA_SUCCESS";
export const UPDATE_MAQUINA = "UPDATE_MAQUINA";
export const UPDATE_MAQUINA_SUCCESS = "UPDATE_MAQUINA_SUCCESS";
export const DELETE_MAQUINA = "DELETE_MAQUINA";
export const DELETE_MAQUINA_SUCCESS = "DELETE_MAQUINA_SUCCESS";
export const TOGGLE_MAQUINA_ESTATUS = "TOGGLE_MAQUINA_ESTATUS";
export const TOGGLE_MAQUINA_ESTATUS_SUCCESS = "TOGGLE_MAQUINA_ESTATUS_SUCCESS";
export const MAQUINAS_ERROR = "MAQUINAS_ERROR";
export const MAQUINAS_PREV_PAGE = "MAQUINAS_PREV_PAGE";
export const MAQUINAS_NEXT_PAGE = "MAQUINAS_NEXT_PAGE";
export const MAQUINAS_CHANGE_PAGE_SIZE = "MAQUINAS_CHANGE_PAGE_SIZE";
export const MAQUINAS_SHOW_MODAL = "MAQUINAS_SHOW_MODAL";
export const MAQUINAS_HIDE_MODAL = "MAQUINAS_HIDE_MODAL";
export const MAQUINAS_SET_EXPORTANDO = "MAQUINAS_SET_EXPORTANDO";
export const GET_MAQUINAS_EXPORTAR = "GET_MAQUINAS_EXPORTAR";
export const GET_MAQUINAS_EXPORTAR_SUCCESS = "GET_MAQUINAS_EXPORTAR_SUCCESS";

export const GET_REPORTES_DIESEL = "GET_REPORTES_DIESEL";
export const GET_REPORTES_DIESEL_SUCCESS = "GET_REPORTES_DIESEL_SUCCESS";
export const GET_REPORTE_DIESEL = "GET_REPORTE_DIESEL";
export const GET_REPORTE_DIESEL_SUCCESS = "GET_REPORTE_DIESEL_SUCCESS";
export const CREATE_REPORTE_DIESEL = "CREATE_REPORTE_DIESEL";
export const CREATE_REPORTE_DIESEL_SUCCESS = "CREATE_REPORTE_DIESEL_SUCCESS";
export const UPDATE_REPORTE_DIESEL = "UPDATE_REPORTE_DIESEL";
export const UPDATE_REPORTE_DIESEL_SUCCESS = "UPDATE_REPORTE_DIESEL_SUCCESS";
export const DELETE_REPORTE_DIESEL = "DELETE_REPORTE_DIESEL";
export const DELETE_REPORTE_DIESEL_SUCCESS = "DELETE_REPORTE_DIESEL_SUCCESS";
export const REPORTES_DIESEL_ERROR = "REPORTES_DIESEL_ERROR";
export const DIESEL_NEXT_PAGE = "DIESEL_NEXT_PAGE";
export const DIESEL_PREV_PAGE = "DIESEL_PREV_PAGE";
export const DIESEL_CHANGE_PAGE_SIZE = "DIESEL_CHANGE_PAGE_SIZE";
export const DIESEL_SHOW_MODAL = "DIESEL_SHOW_MODAL";
export const DIESEL_HIDE_MODAL = "DIESEL_HIDE_MODAL";
export const DIESEL_SET_EXPORTANDO = "DIESEL_SET_EXPORTANDO";
export const GET_REPORTES_DIESEL_EXPORTAR = "GET_REPORTES_DIESEL_EXPORTAR";
export const GET_REPORTES_DIESEL_EXPORTAR_SUCCESS =
  "GET_REPORTES_DIESEL_EXPORTAR_SUCCESS";

export const GET_CLIENTES = "GET_CLIENTES";
export const GET_CLIENTES_SUCCESS = "GET_CLIENTES_SUCCESS";
export const GET_CLIENTE = "GET_CLIENTE";
export const GET_CLIENTE_SUCCESS = "GET_CLIENTE_SUCCESS";
export const CREATE_CLIENTE = "CREATE_CLIENTE";
export const CREATE_CLIENTE_SUCCESS = "CREATE_CLIENTE_SUCCESS";
export const UPDATE_CLIENTE = "UPDATE_CLIENTE";
export const UPDATE_CLIENTE_SUCCESS = "UPDATE_CLIENTE_SUCCESS";
export const DELETE_CLIENTE = "DELETE_CLIENTE";
export const DELETE_CLIENTE_SUCCESS = "DELETE_CLIENTE_SUCCESS";
export const CLIENTES_ERROR = "CLIENTES_ERROR";
export const CLIENTES_PREV_PAGE = "CLIENTES_PREV_PAGE";
export const CLIENTES_NEXT_PAGE = "CLIENTES_NEXT_PAGE";
export const CLIENTES_CHANGE_PAGE_SIZE = "CLIENTES_CHANGE_PAGE_SIZE";
export const CLIENTES_SHOW_MODAL = "CLIENTES_SHOW_MODAL";
export const CLIENTES_HIDE_MODAL = "CLIENTES_HIDE_MODAL";
export const CLIENTES_SET_EXPORTANDO = "CLIENTES_SET_EXPORTANDO";
export const GET_CLIENTES_EXPORTAR = "GET_CLIENTES_EXPORTAR";
export const GET_CLIENTES_EXPORTAR_SUCCESS = "GET_CLIENTES_EXPORTAR_SUCCESS";

export const GET_PROVEEDORES = "GET_PROVEEDORES";
export const GET_PROVEEDORES_SUCCESS = "GET_PROVEEDORES_SUCCESS";
export const GET_PROVEEDOR = "GET_PROVEEDOR";
export const GET_PROVEEDOR_SUCCESS = "GET_PROVEEDOR_SUCCESS";
export const CREATE_PROVEEDOR = "CREATE_PROVEEDOR";
export const CREATE_PROVEEDOR_SUCCESS = "CREATE_PROVEEDOR_SUCCESS";
export const UPDATE_PROVEEDOR = "UPDATE_PROVEEDOR";
export const UPDATE_PROVEEDOR_SUCCESS = "UPDATE_PROVEEDOR_SUCCESS";
export const DELETE_PROVEEDOR = "DELETE_PROVEEDOR";
export const DELETE_PROVEEDOR_SUCCESS = "DELETE_PROVEEDOR_SUCCESS";
export const PROVEEDORES_ERROR = "PROVEEDORES_ERROR";
export const PROVEEDORES_PREV_PAGE = "PROVEEDORES_PREV_PAGE";
export const PROVEEDORES_NEXT_PAGE = "PROVEEDORES_NEXT_PAGE";
export const PROVEEDORES_CHANGE_PAGE_SIZE = "PROVEEDORES_CHANGE_PAGE_SIZE";
export const PROVEEDORES_SHOW_MODAL = "PROVEEDORES_SHOW_MODAL";
export const PROVEEDORES_HIDE_MODAL = "PROVEEDORES_HIDE_MODAL";
export const PROVEEDORES_SET_EXPORTANDO = "PROVEEDORES_SET_EXPORTANDO";
export const GET_PROVEEDORES_EXPORTAR = "GET_PROVEEDORES_EXPORTAR";
export const GET_PROVEEDORES_EXPORTAR_SUCCESS =
  "GET_PROVEEDORES_EXPORTAR_SUCCESS";

export const GET_OPERADORES = "GET_OPERADORES";
export const GET_OPERADORES_SUCCESS = "GET_OPERADORES_SUCCESS";
export const GET_OPERADOR = "GET_OPERADOR";
export const GET_OPERADOR_SUCCESS = "GET_OPERADOR_SUCCESS";
export const CREATE_OPERADOR = "CREATE_OPERADOR";
export const CREATE_OPERADOR_SUCCESS = "CREATE_OPERADOR_SUCCESS";
export const UPDATE_OPERADOR = "UPDATE_OPERADOR";
export const UPDATE_OPERADOR_SUCCESS = "UPDATE_OPERADOR_SUCCESS";
export const DELETE_OPERADOR = "DELETE_OPERADOR";
export const DELETE_OPERADOR_SUCCESS = "DELETE_OPERADOR_SUCCESS";
export const TOGGLE_OPERADOR_ESTATUS = "TOGGLE_OPERADOR_ESTATUS";
export const TOGGLE_OPERADOR_ESTATUS_SUCCESS =
  "TOGGLE_OPERADOR_ESTATUS_SUCCESS";
export const OPERADORES_ERROR = "OPERADORES_ERROR";
export const OPERADORES_PREV_PAGE = "OPERADORES_PREV_PAGE";
export const OPERADORES_NEXT_PAGE = "OPERADORES_NEXT_PAGE";
export const OPERADORES_CHANGE_PAGE_SIZE = "OPERADORES_CHANGE_PAGE_SIZE";
export const OPERADORES_SHOW_MODAL = "OPERADORES_SHOW_MODAL";
export const OPERADORES_HIDE_MODAL = "OPERADORES_HIDE_MODAL";
export const OPERADORES_SET_EXPORTANDO = "OPERADORES_SET_EXPORTANDO";
export const GET_OPERADORES_EXPORTAR = "GET_OPERADORES_EXPORTAR";
export const GET_OPERADORES_EXPORTAR_SUCCESS =
  "GET_OPERADORES_EXPORTAR_SUCCESS";

export const GET_OBRAS = "GET_OBRAS";
export const GET_OBRAS_SUCCESS = "GET_OBRAS_SUCCESS";
export const GET_OBRA = "GET_OBRA";
export const GET_OBRA_SUCCESS = "GET_OBRA_SUCCESS";
export const CREATE_OBRA = "CREATE_OBRA";
export const CREATE_OBRA_SUCCESS = "CREATE_OBRA_SUCCESS";
export const UPDATE_OBRA = "UPDATE_OBRA";
export const UPDATE_OBRA_SUCCESS = "UPDATE_OBRA_SUCCESS";
export const DELETE_OBRA = "DELETE_OBRA";
export const DELETE_OBRA_SUCCESS = "DELETE_OBRA_SUCCESS";
export const OBRAS_ERROR = "OBRAS_ERROR";
export const OBRAS_PREV_PAGE = "OBRAS_PREV_PAGE";
export const OBRAS_NEXT_PAGE = "OBRAS_NEXT_PAGE";
export const OBRAS_CHANGE_PAGE_SIZE = "OBRAS_CHANGE_PAGE_SIZE";
export const OBRAS_SHOW_MODAL = "OBRAS_SHOW_MODAL";
export const OBRAS_HIDE_MODAL = "OBRAS_HIDE_MODAL";
export const OBRAS_SET_EXPORTANDO = "OBRAS_SET_EXPORTANDO";
export const GET_OBRAS_EXPORTAR = "GET_OBRAS_EXPORTAR";
export const GET_OBRAS_EXPORTAR_SUCCESS = "GET_OBRAS_EXPORTAR_SUCCESS";

export const GET_ACCESORIOS = "GET_ACCESORIOS";
export const GET_ACCESORIOS_SUCCESS = "GET_ACCESORIOS_SUCCESS";
export const GET_ACCESORIO = "GET_ACCESORIO";
export const GET_ACCESORIO_SUCCESS = "GET_ACCESORIO_SUCCESS";
export const CREATE_ACCESORIO = "CREATE_ACCESORIO";
export const CREATE_ACCESORIO_SUCCESS = "CREATE_ACCESORIO_SUCCESS";
export const UPDATE_ACCESORIO = "UPDATE_ACCESORIO";
export const UPDATE_ACCESORIO_SUCCESS = "UPDATE_ACCESORIO_SUCCESS";
export const DELETE_ACCESORIO = "DELETE_ACCESORIO";
export const DELETE_ACCESORIO_SUCCESS = "DELETE_ACCESORIO_SUCCESS";
export const TOGGLE_ACCESORIO_ESTATUS = "TOGGLE_ACCESORIO_ESTATUS";
export const TOGGLE_ACCESORIO_ESTATUS_SUCCESS =
  "TOGGLE_ACCESORIO_ESTATUS_SUCCESS";
export const ACCESORIOS_ERROR = "ACCESORIOS_ERROR";
export const ACCESORIOS_PREV_PAGE = "ACCESORIOS_PREV_PAGE";
export const ACCESORIOS_NEXT_PAGE = "ACCESORIOS_NEXT_PAGE";
export const ACCESORIOS_CHANGE_PAGE_SIZE = "ACCESORIOS_CHANGE_PAGE_SIZE";
export const ACCESORIOS_SHOW_MODAL = "ACCESORIOS_SHOW_MODAL";
export const ACCESORIOS_HIDE_MODAL = "ACCESORIOS_HIDE_MODAL";
export const ACCESORIOS_SET_EXPORTANDO = "ACCESORIOS_SET_EXPORTANDO";
export const GET_ACCESORIOS_EXPORTAR = "GET_ACCESORIOS_EXPORTAR";
export const GET_ACCESORIOS_EXPORTAR_SUCCESS =
  "GET_ACCESORIOS_EXPORTAR_SUCCESS";

export const GET_TRASLADOS = "GET_TRASLADOS";
export const GET_TRASLADOS_SUCCESS = "GET_TRASLADOS_SUCCESS";
export const GET_TRASLADO = "GET_TRASLADO";
export const GET_TRASLADO_SUCCESS = "GET_TRASLADO_SUCCESS";
export const CREATE_TRASLADO = "CREATE_TRASLADO";
export const CREATE_TRASLADO_SUCCESS = "CREATE_TRASLADO_SUCCESS";
export const UPDATE_TRASLADO = "UPDATE_TRASLADO";
export const UPDATE_TRASLADO_SUCCESS = "UPDATE_TRASLADO_SUCCESS";
export const DELETE_TRASLADO = "DELETE_TRASLADO";
export const DELETE_TRASLADO_SUCCESS = "DELETE_TRASLADO_SUCCESS";
export const TRASLADOS_ERROR = "TRASLADOS_ERROR";
export const TRASLADOS_PREV_PAGE = "TRASLADOS_PREV_PAGE";
export const TRASLADOS_NEXT_PAGE = "TRASLADOS_NEXT_PAGE";
export const TRASLADOS_CHANGE_PAGE_SIZE = "TRASLADOS_CHANGE_PAGE_SIZE";
export const TRASLADOS_SHOW_MODAL = "TRASLADOS_SHOW_MODAL";
export const TRASLADOS_HIDE_MODAL = "TRASLADOS_HIDE_MODAL";
export const TRASLADOS_SET_EXPORTANDO = "TRASLADOS_SET_EXPORTANDO";
export const GET_TRASLADOS_EXPORTAR = "GET_TRASLADOS_EXPORTAR";
export const GET_TRASLADOS_EXPORTAR_SUCCESS = "GET_TRASLADOS_EXPORTAR_SUCCESS";
export const GET_TRASLADOS_ESTADO = "GET_TRASLADOS_ESTADO";
export const GET_TRASLADOS_ESTADO_SUCCESS = "GET_TRASLADOS_ESTADO_SUCCESS";
export const TRASLADOS_SET_PRETRASLADO = "TRASLADOS_SET_PRETRASLADO";
export const TOOGLE_TRASLADO_ESTATUS = "TOOGLE_TRASLADO_ESTATUS";
export const TOOGLE_TRASLADO_ESTATUS_SUCCESS =
  "TOOGLE_TRASLADO_ESTATUS_SUCCESS";
export const TOGGLE_TRASLADO_REPARACION = "TOGGLE_TRASLADO_REPARACION";
export const TOGGLE_TRASLADO_REPARACION_SUCCESS =
  "TOGGLE_TRASLADO_REPARACION_SUCCESS";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const IS_AUTH = "IS_AUTH";
export const AUTH_ERROR = "AUTH_ERROR";
