import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getTraslados,
  getTrasladosExportar,
  showModal,
} from "../../store/actions/traslados";
import { XlsExport } from "../../components/XlsExport";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputField from "../../components/InputField";

export const BuscarTraslado = () => {
  const pageSize = useSelector((state) => state.traslados.pageSize);
  const isLoading = useSelector((state) => state.traslados.isLoading);
  const exportando = useSelector((state) => state.traslados.exportando);

  const dispatch = useDispatch();

  const fechaHoy = new Date();
  const year = fechaHoy.getFullYear();
  const fechaInicial = year - 2 + "-01-01";
  const fechaFinal = year + "-12-31";

  const initialValues = {
    tipo: "",
    search: "",
    fecha_after: fechaInicial,
    fecha_before: fechaFinal,
  };

  const { control, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: initialValues,
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(
      getTraslados({ page: 1, pageSize, dataBuscar: data, buscando: true })
    );
  };

  const onCleanSearch = (e) => {
    dispatch(
      getTraslados({
        page: 1,
        pageSize,
        buscando: true,
      })
    );
    reset(initialValues);
  };

  const onExportar = () => {
    const dataBuscar = getValues();
    dispatch(getTrasladosExportar({ dataBuscar }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <InputField
              control={control}
              errors={errors}
              name="fecha_after"
              label="Fecha Inicial"
              type="date"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <InputField
              control={control}
              errors={errors}
              name="fecha_before"
              label="Fecha Final"
              type="date"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <InputField
              control={control}
              errors={errors}
              name="tipo"
              label="Tipo"
              type="text"
              select
              options={[
                { value: "", label: "" },
                { value: "movimiento", label: "Movimiento" },
                { value: "cambioOperador", label: "Cambio de Operador" },
              ]}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              control={control}
              errors={errors}
              name="search"
              label="Busqueda"
              type="text"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Buscar
            </Button>
            {"  "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onCleanSearch()}
            >
              Limpiar Busqueda
            </Button>
            {"  "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(showModal())}
            >
              Nuevo Traslado
            </Button>
            {"  "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onExportar()}
            >
              Exportar
            </Button>
          </Grid>
        </Grid>
      </form>
      {exportando && <XlsExport title="Traslados" />}
    </>
  );
};
