import { makeStyles } from '@material-ui/core/styles'


export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));