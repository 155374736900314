import axios from "axios";
import * as types from "../types";
import Cookies from "js-cookie";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const url = process.env.REACT_APP_BACKEND_URL;

// axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("access");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshAuthLogic = (failedRequest) => {
  const refresh = Cookies.get("refresh");
  return axios
    .post(url + "refresh/", { refresh: refresh }, { skipAuthRefresh: true })
    .then((response) => {
      Cookies.set("access", response.data.access, {
        sameSite: "None",
        secure: true,
      });
      Cookies.set("refresh", response.data.refresh, {
        sameSite: "None",
        secure: true,
      });
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
  skipWhileRefreshing: false,
});

export const checkAuth = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(url + "user/");
      dispatch({ type: types.LOGIN_SUCCESS, payload: response.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.LOGIN_FAIL });
    }
  };
};

export const login = ({ username, password }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.LOGIN });
      const tokenResponse = await axios.post(
        url + "token/",
        {
          email: username,
          password,
        },
        { skipAuthRefresh: true }
      );
      const { access, refresh } = tokenResponse.data;
      Cookies.set("access", access, { sameSite: "None", secure: true });
      Cookies.set("refresh", refresh, { sameSite: "None", secure: true });
      const response = await axios.get(url + "user/");
      dispatch({ type: types.LOGIN_SUCCESS, payload: response.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.AUTH_ERROR, payload: error.response });
      dispatch({ type: types.LOGIN_FAIL });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.LOGOUT });
      Cookies.remove("access", { sameSite: "lax" });
      Cookies.remove("refresh", { sameSite: "lax" });
      dispatch({ type: types.LOGOUT_SUCCESS });
    } catch (error) {
      console.log(error);
      dispatch({ type: types.AUTH_ERROR, payload: error.response });
    }
  };
};
