export const trasladosTable = () => [
  { label: "Fecha", key: "fecha" },
  {
    label: "Tipo",
    key: "tipo",
    alias: { movimiento: "Movimiento", cambioOperador: "Cambio Operador" },
  },
  { label: "Maquina", key: "maquina.nombre" },
  { label: "Cliente", key: "cliente.nombre" },
  { label: "Origen", key: "obraOrigen.nombre" },
  { label: "Destino", key: "obraDestino.nombre" },
  { label: "Operador", key: "operador.nombre" },
  { label: "Accesorio", key: "accesorio.nombre" },
  { label: "Proveedor", key: "proveedor.nombre" },
  { label: "Precio Venta", key: "precioVenta" },
];
