import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "800px",
  },
  hover: {
    "&$hover:hover": {
      backgroundColor: "#616161",
    },
  },
  tableCell: {
    "$hover:hover &": {
      color: "white",
    },
  },
  tableCellGreen: {
    backgroundColor: "#66bb6a",
    color: "white",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey.A400,
    color: theme.palette.common.white,
    padding: 5,
  },
  body: {
    padding: 2,
    wordWrap: "none",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  hover: {},
}))(TableRow);

export const StyledTableRowGreen = withStyles((theme) => ({
  root: {
    backgroundColor: "66bb6a",
  },
  hover: {},
}))(TableRow);
