import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportesDiesel } from "../../store/actions/diesel";
import { getMaquinas } from "../../store/actions/maquinas";
import { BuscarDiesel } from "./BuscarDiesel";
import { ListaDiesel } from "./ListaDiesel";
import { ErrorMessage } from "../../components/ErrorMessage";

import Grid from "@material-ui/core/Grid";
import { NuevoReporteDiesel } from "./NuevoReporteDiesel";

export const Diesel = () => {
  // const [, dispatch] = useContext(MaquinasContext);
  const dispatch = useDispatch();
  const pageSize = useSelector((state) => state.diesel.pageSize);
  const error = useSelector((state) => state.diesel.error);
  const showModal = useSelector((state) => state.diesel.showModal);

  useEffect(() => {
    dispatch(getReportesDiesel({ pageSize, page: 1, buscando: true }));
    dispatch(
      getMaquinas({
        page: 1,
        pageSize: 100000,
        dataBuscar: { estatus: "activo" },
      })
    );
  }, [dispatch, pageSize]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BuscarDiesel />
      </Grid>
      <Grid item xs={12}>
        {error && !showModal ? (
          <ErrorMessage module="diesel" />
        ) : (
          <ListaDiesel />
        )}
      </Grid>
      {showModal && <NuevoReporteDiesel />}
    </Grid>
  );
};
