export const fechaHoyIso = () => {
  const hoy = new Date();
  let dd = hoy.getDate();
  let mm = hoy.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd < 10) {
    dd = "0" + dd;
  }
  const yyyy = hoy.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );
export const secondsToIso = (seconds) => {
  const dateStamp = new Date(seconds * 1000);
  let dd = dateStamp.getDate();
  let mm = dateStamp.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd < 10) {
    dd = "0" + dd;
  }
  const yyyy = dateStamp.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

// export const fechaToIso = (date) => {
//     const isoDate = date.toISOString();
//     return isoDate.substr(0, 10);
// }

export const fechaToIso = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (dd < 10) {
    dd = "0" + dd;
  }
  const yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

export const addDays = (days) => {
  const newDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  return newDate;
};

export const addDaysToDate = (date, days) => {
  const dateNow = date.getTime();
  const newDate = new Date(dateNow + days * 24 * 60 * 60 * 1000);
  return newDate;
};

export const isoToDate = (dateIso) => {
  const arr = dateIso.split("-");
  const year = +arr[0];
  const month = +arr[1] - 1;
  const day = +arr[2];
  const date = new Date(year, month, day, 0, 0, 0, 0);
  return date;
};

export const diaDeLaSemana = (date) => {
  const dia = date.getDay();
  switch (dia) {
    case 0:
      return "D";
    case 1:
      return "L";
    case 2:
      return "M";
    case 3:
      return "Mi";
    case 4:
      return "J";
    case 5:
      return "V";
    case 6:
      return "S";
    default:
      break;
  }
};

export const fechaHoyWithoutTime = () => {
  const hoy = new Date();
  const dd = hoy.getDate();
  const mm = hoy.getMonth();
  const yyyy = hoy.getFullYear();
  return new Date(yyyy, mm, dd, 0, 0, 0, 0);
};

export const fechasDiesel = () => {
  const hoy = fechaHoyWithoutTime();
  const dia = hoy.getDay();
  const fechaFinal = 4 - dia >= 0 ? addDays(-(dia + 3)) : addDays(4 - dia);
  const fechaInicial =
    4 - dia >= 0 ? addDays(-(dia + 9)) : addDays(4 - dia - 6);

  return { fechaFinal, fechaInicial };
};

export const leapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getLastMonthDays = () => {
  const hoy = new Date();
  const year = hoy.getFullYear();
  const lastMonth = hoy.getMonth() === 0 ? 12 : hoy.getMonth();
  const lastMonth00 = lastMonth < 10 ? "0" + lastMonth : "" + lastMonth;
  let lastDay = "";

  if (lastMonth === 2) {
    lastDay = leapYear(year) ? "29" : "28";
  } else {
    lastDay =
      lastMonth === 1 ||
      lastMonth === 3 ||
      lastMonth === 5 ||
      lastMonth === 7 ||
      lastMonth === 8 ||
      lastMonth === 10 ||
      lastMonth === 12
        ? "31"
        : "30";
  }

  return {
    firstDay: `${year}-${lastMonth00}-01`,
    lastDay: `${year}-${lastMonth00}-${lastDay}`,
  };
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

const formatAsCurrency = (n, currency) => {
  return currency + " " + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};

export const toCurrency = (monto) => {
  var valor = formatAsCurrency(monto, "$");
  return valor.toString();
};

export const pageOptions = [3, 5, 10, 15, 25];
