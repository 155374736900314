export const dieselTable = () => [
  { label: "Fecha", key: "fecha" },
  { label: "Maquina", key: "maquina.nombre" },
  { label: "Litros", key: "litros" },
  { label: "HorometroAnterior", key: "horometroAnterior" },
  { label: "Horometro", key: "horometro" },
  { label: "Horas Reales", key: "horasReales" },
  { label: "Rendimiento", key: "rendimiento" },
  { label: "Comentarios", key: "comentarios" },
];
