import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { pageOptions } from "../shared";
import TablePagination from "@material-ui/core/TablePagination";

export const PaginationM = ({ module }) => {
  const page = useSelector((state) => state[module].page);
  const pageSize = useSelector((state) => state[module].pageSize);
  const pageMax = useSelector((state) => state[module].pageMax);
  const dataBuscar = useSelector((state) => state[module].dataBuscar);

  const { nextPage, prevPage, changePageSize } = require("../store/actions/" +
    module);
  const dispatch = useDispatch();

  const handlePage = (e, newPage) => {
    if (newPage + 1 > page && !pageMax) {
      dispatch(nextPage({ page, pageSize, dataBuscar }));
    }
    if (newPage + 1 < page && page !== 1) {
      dispatch(prevPage({ page, pageSize, dataBuscar }));
    }
  };

  const handlePageSize = (event) => {
    dispatch(changePageSize(+event.target.value));
  };

  return (
    <TablePagination
      rowsPerPageOptions={pageOptions}
      rowsPerPage={pageSize}
      labelRowsPerPage="Movimientos por pagina:"
      labelDisplayedRows={({ from, to, count }) =>
        // `${from}-${to} de ${count !== -1 ? count : `mas de ${to}`}`
        `${from}-${to}`
      }
      count={-1}
      page={page - 1}
      onPageChange={handlePage}
      onRowsPerPageChange={handlePageSize}
    />
  );
};
