import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideModal,
  createTraslado,
  updateTraslado,
  deleteTraslado,
  toggleEstatus,
  toggleReparacion,
} from "../../store/actions/traslados";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { fechaHoyIso } from "../../shared";
import { ErrorMessage } from "../../components/ErrorMessage";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import InputField from "../../components/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../lib/styles/nuevoStyles";

import {
  trasladoForm,
  defaultVal,
  validation,
} from "../../lib/forms/trasladoForm";

export const NuevoTraslado = () => {
  const maquinas = useSelector((state) => state.maquinas.maquinas);
  const clientes = useSelector((state) => state.clientes.clientes);
  const operadores = useSelector((state) => state.operadores.operadores);
  const proveedores = useSelector((state) => state.proveedores.proveedores);
  const obras = useSelector((state) => state.obras.obras);
  const accesorios = useSelector((state) => state.accesorios.accesorios);
  const showModal = useSelector((state) => state.traslados.showModal);
  const page = useSelector((state) => state.traslados.page);
  const pageSize = useSelector((state) => state.traslados.pageSize);
  const dataBuscar = useSelector((state) => state.traslados.dataBuscar);
  const traslado = useSelector((state) => state.traslados.traslado);
  const editMode = useSelector((state) => state.traslados.editMode);
  const isLoading = useSelector((state) => state.traslados.isLoading);
  const error = useSelector((state) => state.traslados.error);
  const preTraslado = useSelector((state) => state.traslados.preTraslado);
  const dispatch = useDispatch();

  const classes = useStyles();

  const formModel = trasladoForm();
  const defaultValues = defaultVal();
  const validationSchema = validation();

  const [disableCliente, setDisableCliente] = React.useState(false);
  const [disableOperador, setDisableOperador] = React.useState(false);
  const [disableAccesorio, setDisableAccesorio] = React.useState(false);
  const [disableObraDestino, setDisableObraDestino] = React.useState(false);
  const [disableProveedor, setDisableProveedor] = React.useState(false);
  const [disablePrecioVenta, setDisablePrecioVenta] = React.useState(false);

  const proveedorRef = React.useRef();

  console.log(`default values`, defaultValues);

  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: preTraslado
      ? preTraslado
      : editMode
      ? { ...traslado }
      : { ...defaultValues, fecha: fechaHoyIso() },
  });

  const cambioOperadorInit = React.useCallback(() => {
    setDisableCliente(true);
    setDisableObraDestino(true);
    setDisableAccesorio(true);
    setDisableProveedor(true);
    setDisablePrecioVenta(true);
    setValue("cliente", "");
    setValue("obraDestino", "");
    setValue("accesorio", "");
    setValue("proveedor", "");
    setValue("precioVenta", 0);
  }, [setValue]);

  const tallerInit = React.useCallback(() => {
    setDisableCliente(true);
    setDisableOperador(true);
    setDisableAccesorio(true);
    setValue("cliente", "");
    setValue("operador", "");
    setValue("accesorio", "");
    proveedorRef.current.focus();
  }, [setValue]);

  React.useEffect(() => {
    if (editMode) {
      if (traslado.obraDestino?.taller) {
        tallerInit();
      }
      if (traslado.tipo === "cambioOperador") {
        cambioOperadorInit();
      }
    }
  }, [traslado, editMode, cambioOperadorInit, tallerInit]);

  React.useEffect(() => {
    if (preTraslado?.tipo === "cambioOperador") {
      cambioOperadorInit();
    }
  }, [preTraslado, cambioOperadorInit]);

  console.log(preTraslado);

  const onDelete = () => {
    const id = traslado.id;
    if (
      window.confirm(
        `Esta seguro que desea borrar el traslado de la maquina ${traslado.maquina.nombre}, de ${traslado.obraOrigen?.nombre} a ${traslado.obraDestino?.nombre}?`
      )
    ) {
      dispatch(deleteTraslado({ id, page, pageSize, dataBuscar }));
    }
  };

  const onChange = (e, name, value) => {
    if (name === "obraDestino" && value?.taller) {
      tallerInit();
    } else if (name === "obraDestino" && !value?.taller) {
      setDisableCliente(false);
      setDisableOperador(false);
      setDisableAccesorio(false);
    }

    if (name === "tipo" && e.target.value === "cambioOperador") {
      cambioOperadorInit();
    } else if (name === "tipo" && e.target.value !== "cambioOperador") {
      setDisableCliente(false);
      setDisableObraDestino(false);
      setDisableAccesorio(false);
      setDisableProveedor(false);
      setDisablePrecioVenta(false);
    }
  };

  const onToggleStatus = () => {
    const activa = preTraslado.activa;
    const id = preTraslado.id;
    dispatch(
      toggleEstatus({ id, activa: !activa, page, pageSize, dataBuscar })
    );
  };

  const onToggleReparacion = () => {
    const en_reparacion = preTraslado.en_reparacion;
    const id = preTraslado.id;
    dispatch(
      toggleReparacion({
        id,
        en_reparacion: !en_reparacion,
        page,
        pageSize,
        dataBuscar,
      })
    );
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      obraDestino: values.obraDestino?.id || "",
      maquina: values.maquina.id,
      operador: values.operador?.id || "",
      cliente: values.cliente?.id || "",
      proveedor: values.proveedor?.id || "",
      accesorio: values.accesorio?.id || "",
    };
    delete data.obraOrigen;
    console.log(data);
    if (editMode) {
      const id = traslado.id;

      dispatch(updateTraslado({ id, data, page, pageSize, dataBuscar }));
    } else {
      dispatch(createTraslado({ data, pageSize }));
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          dispatch(hideModal());
        }
      }}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editMode ? "Editar Traslado" : "Agregar Nuevo Traslado"}
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <Grid container spacing={2}>
              {formModel.map((input) => (
                <Grid key={input.props.name} item md={input.width} xs={12}>
                  <InputField
                    {...input.props}
                    control={control}
                    errors={errors}
                    inputRef={
                      input.props.name === "proveedor"
                        ? proveedorRef
                        : undefined
                    }
                    onChange={(e, value) =>
                      onChange(e, input.props.name, value)
                    }
                    autoFocus={
                      (input.props.name === "obraDestino" &&
                        preTraslado?.tipo === "movimiento") ||
                      (input.props.name === "operador" &&
                        preTraslado?.tipo === "cambioOperador")
                    }
                    disabled={
                      (input.props.name === "cliente" && disableCliente) ||
                      (input.props.name === "operador" && disableOperador) ||
                      (input.props.name === "accesorio" && disableAccesorio) ||
                      (input.props.name === "proveedor" && disableProveedor) ||
                      (input.props.name === "precioVenta" &&
                        disablePrecioVenta) ||
                      (input.props.name === "obraDestino" &&
                        disableObraDestino) ||
                      input.props.name === "obraOrigen"
                    }
                    acOptions={
                      {
                        maquina: maquinas,
                        cliente: clientes,
                        operador: operadores,
                        proveedor: proveedores,
                        obraDestino: obras,
                        accesorio: accesorios,
                      }[input.props.name]
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          {error && <ErrorMessage module="traslados" />}
        </DialogContent>
        <DialogActions>
          {editMode && (
            <Button onClick={onDelete} className={classes.warning}>
              Borrar Traslado
            </Button>
          )}
          {preTraslado && (
            <Button onClick={onToggleStatus} color="primary">
              {preTraslado?.activa ? "Desactivar Obra" : "Activar Obra"}
            </Button>
          )}
          {preTraslado && (
            <Button onClick={onToggleReparacion} color="primary">
              {preTraslado?.en_reparacion
                ? "Cambiar Estatus Normal"
                : "Cambiar Estatus Reparacion"}
            </Button>
          )}

          <Button
            onClick={() => dispatch(hideModal())}
            color="primary"
            tabIndex={-1}
          >
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isLoading}>
            {editMode ? "Editar" : "Capturar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
