import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccesorio } from "../../store/actions/accesorios";

import { accesoriosTable } from "../../lib/tables/accesoriosTable";
import { Lista } from "../../components/Lista";

export const ListaAccesorios = () => {
  const accesorios = useSelector((state) => state.accesorios.accesorios);

  const dispatch = useDispatch();

  const onUpdate = (id) => {
    dispatch(getAccesorio(id));
  };

  return (
    <Lista
      module="accesorios"
      docs={accesorios}
      onUpdate={onUpdate}
      listaModel={accesoriosTable()}
    />
  );

  // return (
  //   <TableContainer component={Paper}>
  //     <Table size="small" className={classes.table}>
  //       <TableHead>
  //         <TableRow>
  //           <PaginationM module="maquinas" />
  //         </TableRow>
  //         <TableRow>
  //           <TableCell>Nombre</TableCell>
  //           <TableCell>Empresa</TableCell>
  //           <TableCell>Marca</TableCell>
  //           <TableCell>Modelo</TableCell>
  //           <TableCell>Año</TableCell>
  //           <TableCell>Serie</TableCell>
  //           <TableCell>Fecha Compra</TableCell>
  //           <TableCell className={classes.tableCell}>
  //             <IconButton size="small">
  //               <BuildIcon style={{ fontSize: 16 }} />
  //             </IconButton>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {accesorios.map((doc) => (
  //           <StyledTableRow key={doc.id} hover className={classes.hover}>
  //             <TableCell className={classes.tableCell}>{doc.nombre}</TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.empresa === "decmaq" ? "Maquinaria" : "Decoin"}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>{doc.marca}</TableCell>
  //             <TableCell className={classes.tableCell}>{doc.modelo}</TableCell>
  //             <TableCell className={classes.tableCell}>{doc.year}</TableCell>
  //             <TableCell className={classes.tableCell}>{doc.serie}</TableCell>
  //             <TableCell className={classes.tableCell}>
  //               {doc.fechaCompra}
  //             </TableCell>
  //             <TableCell className={classes.tableCell}>
  //               <IconButton size="small" onClick={() => onUpdate(doc.id)}>
  //                 <BuildIcon style={{ fontSize: 16 }} />
  //               </IconButton>
  //             </TableCell>
  //           </StyledTableRow>
  //         ))}
  //       </TableBody>
  //       <TableFooter></TableFooter>
  //     </Table>
  //   </TableContainer>
  // );
};
