import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "clientes/";

export const getClientes =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_CLIENTES });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_CLIENTES_SUCCESS,
        payload: {
          clientes: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLIENTES_NEXT_PAGE });
      dispatch(getClientes({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLIENTES_PREV_PAGE });
      dispatch(getClientes({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CLIENTES_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getClientes({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_HIDE_MODAL });
  };
};

export const createCliente = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_CLIENTE });
      await axios.post(url, data);
      dispatch({ type: types.CREATE_CLIENTE_SUCCESS });
      dispatch(
        getClientes({
          page: 1,
          pageSize,
          buscando: true,
        })
      );
    } catch (error) {
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getCliente = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_CLIENTE });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_CLIENTE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateCliente = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_CLIENTE });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_CLIENTE_SUCCESS });
      dispatch(getClientes({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteCliente = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_CLIENTE });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_CLIENTE_SUCCESS });
      dispatch(getClientes({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const clientesSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.CLIENTES_SET_EXPORTANDO, payload: exportar });
  };
};

export const getClientesExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_CLIENTES_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_CLIENTES_EXPORTAR_SUCCESS,
        payload: {
          clientesExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.CLIENTES_ERROR, payload: error.response });
    }
  };
