import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "accesorios/";

// axios.interceptors.request.use((config) => {
//     const token = Cookies.get('csrftoken');
//     console.log(token);
//     if (token) {
//         config.headers['X-CSRFToken'] = token;
//         console.log(token);
//     }
//     return config;
// }, (error) => {
//     Promise.reject(error);
// });

export const getAccesorios =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_ACCESORIOS });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_ACCESORIOS_SUCCESS,
        payload: {
          accesorios: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ACCESORIOS_NEXT_PAGE });
      dispatch(getAccesorios({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ACCESORIOS_PREV_PAGE });
      dispatch(getAccesorios({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.ACCESORIOS_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getAccesorios({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.ACCESORIOS_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.ACCESORIOS_HIDE_MODAL });
  };
};

export const createAccesorio = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_ACCESORIO });
      const resp = await axios.post(url, data);
      console.log(resp);
      dispatch({ type: types.CREATE_ACCESORIO_SUCCESS });
      dispatch(
        getAccesorios({
          page: 1,
          pageSize,
          buscando: true,
          dataBuscar: { estatus: "activo" },
        })
      );
    } catch (error) {
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getAccesorio = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_ACCESORIO });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_ACCESORIO_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateAccesorio = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_ACCESORIO });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_ACCESORIO_SUCCESS });
      dispatch(getAccesorios({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const toggleEstatus = ({ id, estatus, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.TOGGLE_ACCESORIO_ESTATUS });
      // const token = Cookies.get('csrftoken');
      await axios.patch(`${url}${id}/`, { estatus });
      dispatch({ type: types.TOGGLE_ACCESORIO_ESTATUS_SUCCESS });
      dispatch(getAccesorios({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteAccesorio = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_ACCESORIO });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_ACCESORIO_SUCCESS });
      dispatch(getAccesorios({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const accesoriosSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.ACCESORIOS_SET_EXPORTANDO, payload: exportar });
  };
};

export const getAccesoriosExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_ACCESORIOS_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_ACCESORIOS_EXPORTAR_SUCCESS,
        payload: {
          accesoriosExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.ACCESORIOS_ERROR, payload: error.response });
    }
  };
