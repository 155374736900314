import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import BuildIcon from "@material-ui/icons/Build";
import {
  StyledTableCell as TableCell,
  StyledTableRow,
  useStyles,
} from "../lib/styles/listaStyles";

import { PaginationM } from "./PaginationM";

export const Lista = ({ onDelete, onUpdate, docs, module, listaModel }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <PaginationM module={module} />
            </TableRow>
            <TableRow>
              {listaModel.map((column) => (
                <TableCell key={column.label}>{column.label}</TableCell>
              ))}
              {onUpdate && (
                <TableCell key="headerUpdate" className={classes.tableCell}>
                  <IconButton size="small">
                    <BuildIcon style={{ fontSize: 16 }} />
                  </IconButton>
                </TableCell>
              )}
              {onDelete && (
                <TableCell key="headerDelete" className={classes.tableCell}>
                  <IconButton size="small">
                    <DeleteIcon style={{ fontSize: 16 }} />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc) => (
              <StyledTableRow key={doc.id} hover className={classes.hover}>
                {listaModel.map((column) => {
                  if (column.alias) {
                    return (
                      <TableCell
                        key={`${doc.id}-${column.key}`}
                        className={classes.tableCell}
                      >
                        {column.alias[doc[column.key]]}
                      </TableCell>
                    );
                  }

                  const keys = column.key.split(".");
                  if (keys.length === 2) {
                    return (
                      <TableCell
                        key={`${doc.id}-${column.key}`}
                        className={classes.tableCell}
                      >
                        {doc[keys[0]]?.[keys[1]]}
                      </TableCell>
                    );
                  }

                  if (keys.length === 3) {
                    return (
                      <TableCell
                        key={`${doc.id}-${column.key}`}
                        className={classes.tableCell}
                      >
                        {doc[keys[0]]?.[keys[1]][keys[2]]}
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell
                      key={`${doc.id}-${column.key}`}
                      className={classes.tableCell}
                    >
                      {doc[column.key]}
                    </TableCell>
                  );
                })}
                {onDelete && (
                  <TableCell
                    key={`${doc.id}-delete`}
                    className={classes.tableCell}
                  >
                    <IconButton size="small" onClick={() => onDelete(doc)}>
                      <DeleteIcon style={{ fontSize: 16 }} />
                    </IconButton>
                  </TableCell>
                )}
                {onUpdate && (
                  <TableCell
                    key={`${doc.id}-update`}
                    className={classes.tableCell}
                  >
                    <IconButton size="small" onClick={() => onUpdate(doc.id)}>
                      <BuildIcon style={{ fontSize: 16 }} />
                    </IconButton>
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
