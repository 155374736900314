import axios from "axios";
import * as types from "../types";

const baseUrl = process.env.REACT_APP_BACKEND_URL;
const url = baseUrl + "obras/";

export const getObras =
  ({ page, pageSize, dataBuscar, buscando }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_OBRAS });
    try {
      const response = await axios.get(url, {
        params: {
          page,
          pageSize,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_OBRAS_SUCCESS,
        payload: {
          obras: response.data.results,
          next: response.data.next,
          dataBuscar,
          buscando,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
    }
  };

export const nextPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.OBRAS_NEXT_PAGE });
      dispatch(getObras({ page: page + 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const prevPage = ({ page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.OBRAS_PREV_PAGE });
      dispatch(getObras({ page: page - 1, pageSize, dataBuscar }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changePageSize = (pageSize) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.OBRAS_CHANGE_PAGE_SIZE, payload: pageSize });
      dispatch(getObras({ page: 1, pageSize }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: types.OBRAS_SHOW_MODAL });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({ type: types.OBRAS_HIDE_MODAL });
  };
};

export const createObra = ({ data, pageSize }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_OBRA });
      await axios.post(url, data);
      dispatch({ type: types.CREATE_OBRA_SUCCESS });
      dispatch(
        getObras({
          page: 1,
          pageSize,
          buscando: true,
        })
      );
    } catch (error) {
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
      console.log(error.response);
    }
  };
};

export const getObra = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.GET_OBRA });
      const response = await axios.get(`${url}${id}/`);
      dispatch({ type: types.GET_OBRA_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const updateObra = ({ id, data, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.UPDATE_OBRA });
      // const token = Cookies.get('csrftoken');
      await axios.put(`${url}${id}/`, data);
      dispatch({ type: types.UPDATE_OBRA_SUCCESS });
      dispatch(getObras({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const deleteObra = ({ id, page, pageSize, dataBuscar }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_OBRA });
      await axios.delete(`${url}${id}/`);
      dispatch({ type: types.DELETE_OBRA_SUCCESS });
      dispatch(getObras({ page, pageSize, dataBuscar }));
    } catch (error) {
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
      console.log(error);
    }
  };
};

export const obrasSetExportando = (exportar) => {
  return (dispatch) => {
    dispatch({ type: types.OBRAS_SET_EXPORTANDO, payload: exportar });
  };
};

export const getObrasExportar =
  ({ dataBuscar }) =>
  async (dispatch) => {
    dispatch({ type: types.GET_OBRAS_EXPORTAR });
    try {
      const response = await axios.get(url, {
        params: {
          page: 1,
          pageSize: 100000,
          ...dataBuscar,
        },
      });
      dispatch({
        type: types.GET_OBRAS_EXPORTAR_SUCCESS,
        payload: {
          obrasExportar: response.data.results,
        },
      });
    } catch (error) {
      console.log(error.response);
      dispatch({ type: types.OBRAS_ERROR, payload: error.response });
    }
  };
