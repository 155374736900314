export const accesoriosTable = () => [
  { label: "Nombre", key: "nombre" },
  {
    label: "Empresa",
    key: "empresa",
    alias: { decmaq: "Maquinaria", decoin: "Decoin" },
  },
  { label: "Marca", key: "marca" },
  { label: "Modelo", key: "modelo" },
  { label: "Año", key: "year" },
  { label: "Serie", key: "serie" },
  { label: "Fecha Compra", key: "fechaCompra" },
];
